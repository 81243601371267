import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { useToast, useVerifyCallActions } from '../../../../../../hooks';
import { isValidPhoneNumber } from '../../../../../../utils';
import { AlertType, ButtonVariant, PhoneNumberInput, TextButton } from '../../../../../shared';
import PhoneVerificationModal from '../../../../PhoneVerificationModal';
import { US_COUNTRY_CODE } from '../../../../../../constants';

interface AddPhoneNumberProps {
  index: number;
  user: { id: string; phoneNumbers: string[] };
  onHide: () => void;
}

const AddPhoneNumber = ({ index, user, onHide }: AddPhoneNumberProps) => {
  // State
  // Default to US country code.
  const [countryCode, setCountryCode] = useState(US_COUNTRY_CODE);
  const [error, setError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');

  const { showToast } = useToast();
  const { initiateCall, isLoading } = useVerifyCallActions({
    setError,
    setVerificationCode,
  });

  const fullPhoneNumber = countryCode + phoneNumber;
  // Check the validity of  phone number
  const isVerifyEnabled = isValidPhoneNumber(fullPhoneNumber) && !error.length;

  const resetError = useCallback(() => setError(''), []);

  const onVerify = useCallback(async () => {
    // Check if the new number being saved already exists.
    if (user.phoneNumbers.includes(fullPhoneNumber)) {
      setError('Duplicate phone number');
      return;
    }

    resetError();
    initiateCall(countryCode, phoneNumber);
  }, [countryCode, phoneNumber, user.phoneNumbers, initiateCall, resetError, setError]);

  const onFinish = useCallback(() => {
    onHide();
    resetError();
  }, [onHide, resetError]);

  // Show error toast if there is an error.
  useEffect(() => {
    if (error.length) {
      showToast({ message: error, type: AlertType.ERROR });
    }
  }, [error]);

  return (
    <>
      <div className="flex items-end gap-2">
        <PhoneNumberInput
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter' && isVerifyEnabled) {
              onVerify();
            }
          }}
          isLoading={isLoading}
          error={!!error.length}
          resetError={resetError}
          showLabels={index === 0 && !user.phoneNumbers.length}
        />
        <TextButton
          text="Verify"
          onClick={onVerify}
          loading={isLoading}
          variant={ButtonVariant.OUTLINE}
          tooltip={isVerifyEnabled ? 'Initiate call to verify phone number' : undefined}
          disabled={!isVerifyEnabled}
        />
      </div>
      <PhoneVerificationModal
        setError={setError}
        userId={user.id}
        phoneNumber={fullPhoneNumber}
        verificationCode={verificationCode}
        onSuccess={onFinish}
        resetVerificationCode={() => setVerificationCode('')}
      />
    </>
  );
};

export default AddPhoneNumber;

import { ReactNode } from 'react';

export enum DialogType {
  CONFIRM = 'confirm',
  DEFAULT = 'default',
  SETTINGS = 'settings',
}

export interface DialogProps {
  // Content to be rendered inside the modal.
  children: ReactNode;
  // Controls the visibility of the modal.
  isOpen: boolean;
  // Optional description text.
  description?: string;
  // Whether to hide the close button.
  hideCloseButton?: boolean;
  // Optional title text.
  title?: string;
  type?: DialogType;
  // Optional callback function to run when the modal is requested to close.
  onClose?: () => void;
  footer?: ReactNode;
  className?: string;
}

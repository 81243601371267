import { BaseSelect } from '../BaseSelect';
import { SingleSelectProps } from './SingleSelect.types';

/**
 * SingleSelect is a dropdown component that allows users to select a single option.
 * It extends the BaseSelect component and adds specific logic for single-select behavior.
 */
const SingleSelect = ({ clearable, loading, options, selected, onChange, ...props }: SingleSelectProps) => {
  return (
    <BaseSelect
      {...props}
      clearable={clearable && !!selected}
      loading={loading}
      options={options.map((option) => ({ ...option, active: option.value === selected?.value }))}
      selected={selected}
      displayValue={loading ? 'Loading...' : selected?.label || ''}
      displayValueColor={selected?.color}
      handleOptionSelection={onChange}
      handleClear={() => onChange(undefined)}
    />
  );
};

export default SingleSelect;

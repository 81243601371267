import { useCallback, useState } from 'react';
import { useHandleApiResponse, useToast } from '../../../../../../hooks';
import { useDeleteUserPhoneNumberMutation } from '../../../../../../services';
import {
  AlertType,
  ButtonColor,
  ButtonVariant,
  Icon,
  IconButton,
  PhoneNumberInput,
  Typography,
  TypographyWeight,
} from '../../../../../shared';
import ConfirmModal from '../../../../ConfirmModal';

const ERROR_MSG = 'Failed to delete phone number';
const SUCCESS_MSG = 'Phone number deleted successfully';

// Extracts the country code and phone number from a phone number string.
const extractCountryCodeAndNumber = (phoneNumber: string): { countryCode?: string; number: string } => {
  // Define a regex pattern for valid country codes (up to 3 digits) and the rest of the number.
  const countryCodePattern = /^\+(\d{1,3})(\d{10,})$/;

  const match = phoneNumber.match(countryCodePattern);
  if (match) {
    // If a match is found, return the country code and the rest of the phone number.
    const countryCode = match[1];
    const number = match[2];
    return { countryCode: `+${countryCode}`, number };
  } else {
    // If no match is found, return the original phone number.
    return { countryCode: undefined, number: phoneNumber };
  }
};

interface PhoneNumberRowProps {
  index: number;
  phoneNumber: string;
  userId: string;
}

const PhoneNumberRow = ({ index, phoneNumber, userId }: PhoneNumberRowProps) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  const [deleteUserPhoneNumber, { isLoading }] = useDeleteUserPhoneNumberMutation();

  // Handles deleting a phone number from the user profile.
  const handleDeletePhoneNumber = useCallback(async () => {
    try {
      const response = await deleteUserPhoneNumber({ id: userId, phoneNumber });
      handleApiResponse({ response, errorMsg: ERROR_MSG, successMsg: SUCCESS_MSG });
    } catch (error) {
      console.error(`${ERROR_MSG}: `, error);
      showToast({ message: ERROR_MSG, type: AlertType.ERROR });
    }
  }, [handleApiResponse, showToast]);

  return (
    <>
      <div key={phoneNumber} className="flex items-end gap-2">
        <PhoneNumberInput
          countryCode={extractCountryCodeAndNumber(phoneNumber).countryCode || ''}
          phoneNumber={extractCountryCodeAndNumber(phoneNumber).number}
          showLabels={index === 0}
        />
        <IconButton
          icon={Icon.TRASH}
          variant={ButtonVariant.GHOST}
          onClick={() => setIsConfirmOpen(true)}
          tooltip="Delete phone number"
          loading={isLoading}
          color={ButtonColor.DESTRUCTIVE}
        />
      </div>
      <ConfirmModal
        buttonText="Delete"
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
        title="Confirm delete phone number"
        destructive
        confirmText={
          <Typography>
            Are you sure you want to delete the phone number{' '}
            <Typography weight={TypographyWeight.MEDIUM}>{phoneNumber}</Typography>?
          </Typography>
        }
        onConfirm={handleDeletePhoneNumber}
      />
    </>
  );
};

export default PhoneNumberRow;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Accordion, ButtonColor, Icon, Spinner, Tabs, TextButton } from '../../components';
import { AppRoutes } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setCurrentCallDetails } from '../../redux/reducers';
import { useGetCallQuery } from '../../services';
import { Alignment, CallSpeaker, CallSpeechCategory } from '../../types';
import { getCallerInfo } from '../../utils';
import CallSpeechRow from './CallSpeechRow';
import CallStatsPanel from './CallStatsPanel';
import CallTranscriptRow, { Utterance } from './CallTranscriptRow';

// Enum for tab identifiers
enum CallDetailsTabs {
  OBJECTIONS_AND_QUESTIONS = 'OBJECTIONS_AND_QUESTIONS',
  TRANSCRIPT = 'TRANSCRIPT',
}

const CallDetailsPage = () => {
  // Get the call SID from the URL parameters.
  const { callSid } = useParams();
  const navigate = useNavigate();

  // State to manage the active tab.
  const [activeTab, setActiveTab] = useState(CallDetailsTabs.OBJECTIONS_AND_QUESTIONS);
  const [transcriptionData, setTranscriptData] = useState<Utterance[]>([]);

  const dispatch = useAppDispatch();
  // Get the current call SID from the Redux store.
  const currentCallSid = useAppSelector((state) => state.callHistory.currentCallDetails?.callSid);
  const user = useAppSelector((state) => state.auth.user);

  // Fetch call data using the call ID.
  const { data: call, isLoading, error } = useGetCallQuery(callSid as string);

  // Get prospect name from call data.
  const prospectName = useMemo(() => {
    if (call) {
      const firstName = call.practiceProspect.firstName;
      const lastName = call.practiceProspect.lastName;
      return `${firstName} ${lastName}`;
    } else {
      return '';
    }
  }, [call?.practiceProspect]);

  // Get agent details from call data and user info.
  const agentDetails: { name: string; picture?: string } = useMemo(() => {
    if (call && user) {
      return getCallerInfo(call, user);
    } else {
      return { name: '' };
    }
  }, [call, user]);

  // Memoized list of objections from the call data.
  const objections = useMemo(
    () =>
      (call?.callSpeechCategories || []).filter(
        (category: CallSpeechCategory) => category.speaker === CallSpeaker.CUSTOMER
      ) || [],
    [call?.callSpeechCategories]
  );

  // Memoized list of questions from the call data.
  const questions = useMemo(
    () =>
      (call?.callSpeechCategories || []).filter(
        (category: CallSpeechCategory) => category.speaker === CallSpeaker.AGENT
      ) || [],
    [call?.callSpeechCategories]
  );

  // Dispatches action to seek the current call to a specific time.
  const seekAudio = useCallback(
    (startTime: number) => {
      if (callSid) {
        // Enable auto-playing the audio to provide a seamless experience for the user,
        // enabling them to immediately hear the context of the selected transcript time.
        dispatch(setCurrentCallDetails({ callSid, startTime, autoPlay: true }));
      }
    },
    [dispatch]
  );

  // Fetch transcription data.
  useEffect(() => {
    if (call?.transcriptionPath) {
      fetch(call.transcriptionPath)
        .then((response) => response.json())
        .then((data) => {
          setTranscriptData(data);
        })
        .catch((error) => {
          console.error('Error fetching transcription data:', error);
        });
    }
  }, [call?.transcriptionPath]);

  // Define the tabs for the page.
  const tabs = [
    {
      id: CallDetailsTabs.OBJECTIONS_AND_QUESTIONS,
      title: 'Objections & Questions',
      icon: Icon.COMMENT,
      content: (
        <Accordion
          items={[
            {
              id: 'objections-and-questions',
              title: 'Prospect Objections & Questions',
              content: (
                <div className="mt-4 flex flex-col gap-2">
                  {objections.map((objection) => (
                    <CallSpeechRow
                      key={objection.startTime}
                      seekToAudio={() => seekAudio(objection.startTime)}
                      {...objection}
                    />
                  ))}
                </div>
              ),
            },
            {
              id: 'discovery-questions',
              title: 'Discovery Questions',
              content: (
                <div className="mt-4 flex flex-col gap-2">
                  {questions.map((question) => (
                    <CallSpeechRow
                      key={question.startTime}
                      seekToAudio={() => seekAudio(question.startTime)}
                      {...question}
                    />
                  ))}
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      id: CallDetailsTabs.TRANSCRIPT,
      title: 'Transcript',
      icon: Icon.CAPTION,
      content: (
        <div className="flex flex-col gap-2">
          {transcriptionData.map((transcript) => (
            <CallTranscriptRow
              key={transcript.id}
              startTime={transcript.start}
              seekToAudio={() => seekAudio(transcript.start)}
              speakerName={transcript.speaker === CallSpeaker.AGENT ? agentDetails.name : prospectName}
              speakerPicture={transcript.speaker === CallSpeaker.AGENT ? agentDetails.picture : undefined}
              {...transcript}
            />
          ))}
        </div>
      ),
    },
  ];

  // Handles opening the media player if the call audio path exists
  // by setting the call SID in the Redux store.
  useEffect(() => {
    if (currentCallSid !== callSid && !!call?.audioPath) {
      dispatch(setCurrentCallDetails({ callSid: callSid as string }));
    }
  }, [callSid, currentCallSid, call?.audioPath]);

  // Function to go back to the Call History page
  const goBack = useCallback(() => navigate(AppRoutes.CALL_HISTORY), [navigate]);

  const renderBackButton = () => (
    <TextButton text="Back" onClick={goBack} startIcon={Icon.CHEVRON_LEFT} color={ButtonColor.SECONDARY} />
  );

  // Handles invalid call SIDs.
  if (error) {
    return (
      <div className="flex flex-col gap-4">
        {renderBackButton()}
        <p>Error: Invalid call ID</p>
      </div>
    );
  }

  // Show a loading spinner while the call data is being fetched.
  if (isLoading)
    return (
      <div className="flex h-full justify-center">
        <Spinner />
      </div>
    );

  // Return null if no call data is available.
  if (!call) {
    return null;
  }

  return (
    <div className="flex w-full flex-col gap-4">
      {renderBackButton()}
      <div className="flex w-full gap-4">
        <div className="w-full">
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={(newActiveTab) => setActiveTab(newActiveTab as CallDetailsTabs)}
            alignment={Alignment.LEFT}
            className="!gap-4"
          />
        </div>
        <CallStatsPanel {...call} />
      </div>
    </div>
  );
};

export default CallDetailsPage;

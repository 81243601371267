import { SelectOption } from '../components';
import { snakeCaseToLabel } from './string.utils';

/**
 * Parses a required value into a SelectOption shape (label and value).
 */
export const parseRequiredSelectOption = (value: string): SelectOption => ({ label: snakeCaseToLabel(value), value });

/**
 * Converts an optional array of strings into an array of SelectOption objects.
 * Each string is transformed into a SelectOption with a label (converted from snake case)
 * and a value. If the input is undefined, the function returns undefined.
 */
export const parseOptionalSelectOption = (value?: string[]): SelectOption[] | undefined => {
  if (!value) return undefined;
  return value.map((v) => ({ label: snakeCaseToLabel(v), value: v }));
};

/**
 * Finds the selected options from the options list based on the selected value.
 */
export const getSelectedOptions = (
  options: { value: string; label: string; color?: string }[],
  selectedValue?: string[]
): SelectOption[] => {
  return options.filter((option) => selectedValue?.includes(option.value));
};

import { useCallback } from 'react';
import { ComponentSize, TextColor } from '../../../types';
import { TextInput, TextInputType } from '../TextInput';
import { Typography, TypographySize } from '../Typography';
import { PhoneNumberInputProps } from './PhoneNumberInput.types';
import { formatInputPhoneNumber, isValidCountryCode } from '../../../utils';

const MAX_COUNTRY_CODE_FIELD_WIDTH = 100;

const PhoneNumberInput = ({
  countryCode,
  phoneNumber,
  error,
  isLoading,
  message,
  showLabels,
  size = ComponentSize.SMALL,
  onKeyDown,
  resetError,
  setCountryCode,
  setPhoneNumber,
}: PhoneNumberInputProps) => {
  const onCountryCodeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!setCountryCode) return;

      const newCode = e.target.value;
      if (isValidCountryCode(newCode)) {
        setCountryCode(newCode);
        resetError?.();
      }
    },
    [resetError, setCountryCode]
  );

  const onPhoneNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!setPhoneNumber) return;

      const inputNumber = e.target.value;
      // Strip any formatting from the input number to update the raw state
      const strippedNumber = inputNumber.replace(/\D/g, ''); // Removes non-numeric characters
      setPhoneNumber(strippedNumber);

      resetError?.();
    },
    [resetError, setPhoneNumber]
  );

  // Format the phone number for display
  const formattedPhoneNumber = formatInputPhoneNumber(phoneNumber, countryCode);

  return (
    <div className="w-full">
      <div className="flex w-full items-start gap-2">
        <div className="flex w-full flex-col gap-2" style={{ maxWidth: MAX_COUNTRY_CODE_FIELD_WIDTH }}>
          {showLabels && <Typography color={TextColor.SECONDARY}>Country code</Typography>}
          <TextInput
            value={countryCode}
            size={size}
            onKeyDown={onKeyDown}
            onChange={onCountryCodeChange}
            disabled={isLoading || !setCountryCode}
            error={error}
          />
        </div>
        <div className="flex w-full flex-col gap-2">
          {showLabels && <Typography color={TextColor.SECONDARY}>Phone number</Typography>}
          <TextInput
            placeholder="Phone number*"
            size={size}
            // Display formatted phone number
            value={formattedPhoneNumber}
            // Handle input change with raw number stripping
            onChange={onPhoneNumberChange}
            type={TextInputType.TEL}
            onKeyDown={onKeyDown}
            disabled={isLoading || !setPhoneNumber}
            error={error}
          />
        </div>
      </div>
      {!!message && (
        <div className="h-4">
          <Typography
            size={TypographySize.CAPTION}
            color={message.destructive ? TextColor.DESTRUCTIVE : TextColor.SECONDARY}
          >
            {message.text}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default PhoneNumberInput;

import { Badge, BadgeColor, Icon, Icons, Typography, TypographySize } from '../../components';
import { CallSpeaker, ComponentSize, TextColor } from '../../types';
import { capitalizeFirstLetter, formatDuration } from '../../utils';

const BADGE_WIDTH = 180;

type CallSpeechRowProps = {
  label: string;
  speaker: CallSpeaker;
  summary: string;
  startTime: number;
  seekToAudio: () => void;
};

const CallSpeechRow = ({ label, speaker, summary, startTime, seekToAudio }: CallSpeechRowProps) => {
  return (
    <div className="flex items-center gap-4">
      <div className="flex cursor-pointer items-center gap-1" onClick={seekToAudio}>
        <Icons icon={Icon.PLAY} size={ComponentSize.X_SMALL} color={TextColor.LINK} />
        <Typography size={TypographySize.H5} underline color={TextColor.LINK}>
          {formatDuration(startTime)}
        </Typography>
      </div>
      <div className="flex justify-center" style={{ width: BADGE_WIDTH }}>
        <Badge
          label={capitalizeFirstLetter(label)}
          color={speaker === CallSpeaker.AGENT ? BadgeColor.SUCCESS : BadgeColor.ERROR}
          size={ComponentSize.MEDIUM}
          maxWidth={BADGE_WIDTH}
        />
      </div>
      <Typography size={TypographySize.H5}>{summary}</Typography>
    </div>
  );
};

export default CallSpeechRow;

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { CustomAuth0Provider, CustomRouterProvider, ToastProvider } from './context';
import { LaunchDarklyProvider } from './context/LaunchDarklyProvider';
import { useAppSelector, useSyncAuthState } from './hooks';
import { persistor, store } from './redux/store';
import * as FullStory from '@fullstory/browser';
import { NoInternetConnectionPage } from './pages';
import { useRUM } from './hooks/useRUM';

const AppContent = () => {
  // Synchronize Auth0 authentication state with Redux.
  useSyncAuthState();
  useRUM();

  const hasInternetConnection = useAppSelector((state) => state.connection.hasInternetConnection);

  if (!hasInternetConnection) {
    return <NoInternetConnectionPage />;
  }

  return (
    <LaunchDarklyProvider>
      <ToastProvider>
        <CustomRouterProvider />
      </ToastProvider>
    </LaunchDarklyProvider>
  );
};

/**
 * App component sets up the React and Redux environment.
 * It wraps the app content in necessary providers for state management and authentication.
 */
const App = () => {
  useEffect(() => {
    if (process.env.REACT_APP_FULLSTORY_ORG_ID) {
      FullStory.init({ orgId: process.env.REACT_APP_FULLSTORY_ORG_ID });
    }
  }, []);

  return (
    <React.StrictMode>
      <CustomAuth0Provider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AppContent />
          </PersistGate>
        </Provider>
      </CustomAuth0Provider>
    </React.StrictMode>
  );
};

export default App;

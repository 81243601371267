import { Dispatch, SetStateAction, useCallback } from 'react';
import { useInitiateCallMutation } from '../services';

const COUNTRY_CODE_REQUIRED_ERROR_MSG = 'Country code is required';
const EMPTY_PHONE_NUMBER_ERROR_MSG = 'Required fields cannot be empty';
const INVALID_PHONE_NUMBER_ERROR_MSG = 'Invalid phone number';
const VERIFICATION_FAILED_ERROR_MSG = 'Cannot verify phone number';

const useVerifyCallActions = ({
  setError,
  setVerificationCode,
}: {
  setError: Dispatch<SetStateAction<string>>;
  setVerificationCode: Dispatch<SetStateAction<string>>;
}) => {
  const [runInitiateCall, { isLoading }] = useInitiateCallMutation();

  // Handler for the "Verify" button click.
  // 1. Validate form fields.
  // 2. Initiate a phone verification call.
  // 3. If the call is successful, show the phone verification modal.
  const initiateCall = useCallback(
    async (countryCode: string, phoneNumber: string) => {
      if (!phoneNumber.length) {
        setError(EMPTY_PHONE_NUMBER_ERROR_MSG);
        return;
      }

      if (countryCode === '+') {
        setError(COUNTRY_CODE_REQUIRED_ERROR_MSG);
        return;
      }

      //Regex check for invalid phone numbers
      const newPhoneNumber = countryCode + phoneNumber;
      if (!/^\+\d{10,15}$/.test(newPhoneNumber)) {
        setError(INVALID_PHONE_NUMBER_ERROR_MSG);
        return;
      }

      // Initiate a phone verification call.
      const call = await runInitiateCall({ to: newPhoneNumber });

      // If the call is successful, show the phone verification modal.
      if (!call.error && call.data) {
        setVerificationCode(call.data.verificationCode);
      } else {
        setError(VERIFICATION_FAILED_ERROR_MSG);
      }
    },
    [runInitiateCall, setError, setVerificationCode]
  );

  return { initiateCall, isLoading };
};

export default useVerifyCallActions;

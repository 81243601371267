import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { ButtonColor, TextButton, Typography, TypographyWeight } from '../../components';
import { ComponentSize, TextColor } from '../../types';

const AccessDenied: React.FC = () => {
  const [countdown, setCountdown] = useState(5); // Start countdown from 5 seconds
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const { logout } = useAuth0();

  const runLogout = () => {
    setIsLoggingOut(true);
    logout();
  };

  useEffect(() => {
    // Timer to handle countdown and logout logic.
    const timerId = setInterval(() => {
      setCountdown((currentCount) => {
        // Logout and stop the timer when countdown reaches 1.
        if (currentCount === 1) {
          runLogout();
          clearInterval(timerId);
        }
        // Decrement the countdown.
        return currentCount - 1;
      });
    }, 1000);

    // Cleanup function to clear the timer when the component unmounts.
    return () => {
      clearInterval(timerId);
      setIsLoggingOut(false);
    };
  }, []);

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="flex flex-col items-center gap-4">
        <Typography color={TextColor.DESTRUCTIVE} weight={TypographyWeight.BOLD} className="!text-6xl">
          Access Denied
        </Typography>
        <div className="flex flex-col items-center gap-2">
          <Typography weight={TypographyWeight.SEMI_BOLD} color={TextColor.SECONDARY} className="!text-2xl">
            You do not have permission to access this organization.
          </Typography>
          <Typography color={TextColor.SECONDARY}>
            {isLoggingOut && 'Logging out...'}
            {!isLoggingOut &&
              `You will be logged out automatically in ${countdown} second${countdown > 1 ? 's' : ''} for security reasons.`}
          </Typography>
        </div>
        <TextButton
          onClick={runLogout}
          disabled={isLoggingOut}
          text="Log Out Now"
          size={ComponentSize.MEDIUM}
          color={ButtonColor.SECONDARY}
        />
      </div>
    </div>
  );
};

export default AccessDenied;

import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import fullyRampedLogo from '../../../assets/logo-light.png';
import {
  JOIN_ROOM_EVENT,
  VERIFICATION_FAILED_ERROR_MSG,
  VERIFICATION_FAILED_EVENT,
  VERIFICATION_SUCCESS_EVENT,
} from '../../../constants';
import { useHandleApiResponse } from '../../../hooks';
import { socket, useConfirmUserPhoneNumberMutation } from '../../../services';
import {
  ButtonColor,
  ButtonVariant,
  Dialog,
  TextButton,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../shared';

interface PhoneVerificationProps {
  // Phone number to verify.
  phoneNumber: string;
  // User ID to verify phone number for.
  userId: string;
  // Verification code to verify phone number with.
  // Controls whether the modal is open.
  verificationCode: string;
  // Callback to call when verification is successful.
  onSuccess: () => void;
  // Callback to call when verification code is reset.
  // This is used to close the verification modal.
  resetVerificationCode: () => void;
  // Callback to call when verification is unsuccessful.
  setError: Dispatch<SetStateAction<string>>;
  // Controls whether the logo is shown.
  showLogo?: boolean;
  // Callback to call when user ends the call before verification is successful.
  setCallEnded?: Dispatch<SetStateAction<boolean>>;
}

const PhoneVerificationModal = ({
  userId,
  phoneNumber,
  verificationCode,
  onSuccess,
  resetVerificationCode,
  setError,
  setCallEnded,
  showLogo,
}: PhoneVerificationProps) => {
  const handleApiResponse = useHandleApiResponse();

  const [confirmUserPhoneNumber] = useConfirmUserPhoneNumberMutation();

  // Whether the modal is open.
  const isOpen = useMemo(() => !!verificationCode.length, [verificationCode]);

  // Handles error event.
  const handleError = useCallback(() => {
    // Resetting the verification code closes the verification modal.
    resetVerificationCode();
    // Set error message.
    setError(VERIFICATION_FAILED_ERROR_MSG);
  }, [resetVerificationCode, setError]);

  // Handles phone verified event.
  const handlePhoneVerified = useCallback(async () => {
    try {
      // Add the phone number to the user's account.
      const response = await confirmUserPhoneNumber({ id: userId, phoneNumber });
      handleApiResponse({ response, errorMsg: VERIFICATION_FAILED_ERROR_MSG, onError: handleError, onSuccess });
    } catch (error) {
      console.error(`${VERIFICATION_FAILED_ERROR_MSG}: `, error);
      handleError();
    }
  }, [confirmUserPhoneNumber, handleApiResponse, onSuccess, userId, phoneNumber, handleError]);

  useEffect(() => {
    if (!isOpen) return;

    socket.connect();

    // Join a room based on the phone number.
    socket.emit(JOIN_ROOM_EVENT, phoneNumber);

    // Listen for verification-success and verification-failed events.
    socket.on(VERIFICATION_SUCCESS_EVENT, handlePhoneVerified);
    socket.on(VERIFICATION_FAILED_EVENT, handleError);

    // Clean up the event listeners when the component unmounts or dependencies change.
    return () => {
      socket.off(VERIFICATION_SUCCESS_EVENT, handlePhoneVerified);
      socket.off(VERIFICATION_FAILED_EVENT, handleError);
      socket.disconnect();
    };
  }, [isOpen, phoneNumber, handleError, handlePhoneVerified]);

  // Handles closing the verification modal.
  const onClose = useCallback(() => {
    // Resetting the verification code closes the verification modal.
    resetVerificationCode();
    if (setCallEnded) {
      setCallEnded(true);
    }
  }, [resetVerificationCode, setCallEnded]);

  return (
    <Dialog isOpen={isOpen} hideCloseButton>
      <div className="flex flex-col items-center gap-8 p-4">
        {showLogo && <img src={fullyRampedLogo} alt="FullyRamped Logo" className="h-14 w-14" />}
        <Typography size={TypographySize.H5}>Calling your phone...</Typography>
        <div className="flex flex-col items-center gap-4">
          <Typography size={TypographySize.H5}>Dial the code</Typography>
          <Typography size={TypographySize.H1} weight={TypographyWeight.SEMI_BOLD}>
            {verificationCode}
          </Typography>
          <Typography size={TypographySize.H5}>when prompted.</Typography>
        </div>
        <TextButton text="Cancel" color={ButtonColor.DESTRUCTIVE} onClick={onClose} variant={ButtonVariant.OUTLINE} />
      </div>
    </Dialog>
  );
};

export default PhoneVerificationModal;

import * as RadixAccordion from '@radix-ui/react-accordion';
import { ReactNode, useState } from 'react';
import { Typography, TypographySize, TypographyWeight } from '../Typography';
import AccordionContent from './AccordionContent';
import AccordionTrigger from './AccordionTrigger';
import clsx from 'clsx';

type AccordionItem = {
  // Unique identifier for accordion items.
  id: string;
  title: string;
  content?: ReactNode;
};

interface AccordionProps {
  items: AccordionItem[];
  className?: string;
}

const Accordion = ({ items, className }: AccordionProps) => {
  const [activeItem, setActiveItem] = useState(items[0]?.id);

  return (
    <RadixAccordion.Root
      type="single"
      value={activeItem}
      collapsible
      className={clsx('flex flex-col gap-4', className)}
      onValueChange={setActiveItem}
    >
      {items.map(({ id, title, content }) => (
        <RadixAccordion.Item className="collapse bg-base-100 p-4" value={id} key={id}>
          <AccordionTrigger isActive={id === activeItem}>
            <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
              {title}
            </Typography>
          </AccordionTrigger>
          <AccordionContent>{content}</AccordionContent>
        </RadixAccordion.Item>
      ))}
    </RadixAccordion.Root>
  );
};

export default Accordion;

import { ComponentSize, TextColor } from '../../../types';
import { Icon, Icons, Typography, TypographySize, TypographyWeight } from '../../shared';

interface SubmitSuccessStateProps {
  handleSubmitAnother: () => void;
}

const SubmitSuccessState = ({ handleSubmitAnother }: SubmitSuccessStateProps) => {
  return (
    <div className="flex flex-col items-center gap-2 p-1">
      <div className="flex items-center gap-2">
        <Icons icon={Icon.CIRCLE_CHECK} color={TextColor.SUCCESS} size={ComponentSize.LARGE} />
        <Typography size={TypographySize.H5} weight={TypographyWeight.SEMI_BOLD}>
          Thank you!
        </Typography>
      </div>
      <Typography underline size={TypographySize.CAPTION} color={TextColor.SECONDARY} onClick={handleSubmitAnother}>
        Submit another?
      </Typography>
    </div>
  );
};

export default SubmitSuccessState;

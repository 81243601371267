export enum DateFormat {
  WEEKDAY = 'ddd',
  DAY = 'D',
  FULL_DATE = 'MM/DD/YYYY',
  MONTH_DAY = 'MMM D',
}

export enum TimeFormat {
  FULL_TIME = 'hh:mm A',
  SHORT_TIME = 'h:mm A',
}

export enum DateUnit {
  MONTH = 'month',
  DAY = 'day',
  WEEK = 'week',
}

export enum DateOptions {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  THIS_WEEK = 'THIS_WEEK',
  LAST_WEEK = 'LAST_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
}

import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import fullyRampedLogo from '../../assets/logo-light.png';
import {
  Avatar,
  ButtonColor,
  ButtonVariant,
  Dialog,
  PhoneNumberInput,
  TextButton,
  TextInput,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../components';
import { PhoneVerificationModal } from '../../components/modals';
import { AppRoutes, CANCELLED_VERIFICATION_ERROR_MSG, US_COUNTRY_CODE } from '../../constants';
import { useAppDispatch, useAppSelector, useHandleApiResponse, useVerifyCallActions } from '../../hooks';
import { setIsOnboarded } from '../../redux/reducers';
import { useOnboardUserMutation } from '../../services';
import { Alignment, ComponentSize, Permissions, TextColor } from '../../types';
import { isValidPhoneNumber, splitName } from '../../utils';

const ONBOARD_USER_ERROR_MSG = 'Failed to onboard user';

const FinishSetupPage = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();

  // Redux
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);

  // State for form fields and their errors.
  const [callEnded, setCallEnded] = useState(false);
  const [countryCode, setCountryCode] = useState(US_COUNTRY_CODE);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [verificationCode, setVerificationCode] = useState('');

  // Mutations
  const [onboardUser, { isLoading: isOnboarding }] = useOnboardUserMutation();

  // Custom hooks
  const handleApiResponse = useHandleApiResponse(true);
  const { initiateCall, isLoading: isVerifying } = useVerifyCallActions({
    setError: setPhoneNumberError,
    setVerificationCode,
  });

  // Controls opening/closing the verification modal depending on the verification code.
  const isVerificationModalOpen = useMemo(() => !!verificationCode.length, [verificationCode]);

  if (!user?.id) return null;

  // Splits the user's name into first and last name.
  const [firstName, lastName] = splitName(user.name);
  // Checks if the user has the permission to onboard.
  const canOnboardUser = user?.permissions?.includes(Permissions.ONBOARD_USER);
  // The full phone number is the country code plus the phone number.
  const fullPhoneNumber = countryCode + phoneNumber;
  // Enables the verify button if the phone number is valid and there is no error.
  const isVerifyEnabled = isValidPhoneNumber(fullPhoneNumber) && !phoneNumberError.length;

  // Resets the phone number error and call ended state.
  const resetError = useCallback(() => {
    setPhoneNumberError('');
    setCallEnded(false);
  }, [setPhoneNumberError, setCallEnded]);

  // Starts the phone verification process.
  const onVerify = useCallback(() => {
    resetError();
    initiateCall(countryCode, phoneNumber);
  }, [countryCode, phoneNumber, initiateCall, resetError]);

  const handleOnboardUser = useCallback(async () => {
    try {
      const response = await onboardUser(user.id);

      // On error, closes the verification modal by resetting the verification code.
      const onError = () => {
        setVerificationCode('');
      };

      // On success, sets the user as onboarded and navigates to the practice page.
      const onSuccess = () => {
        dispatch(setIsOnboarded(true));
        navigate(AppRoutes.PRACTICE);
      };

      handleApiResponse({
        response,
        errorMsg: ONBOARD_USER_ERROR_MSG,
        onError,
        onSuccess,
      });
    } catch (error) {
      console.error(`${ONBOARD_USER_ERROR_MSG}: `, error);
    }
  }, [user.id, onboardUser, dispatch, navigate, setVerificationCode]);

  return (
    <div className="h-screen bg-base-200">
      <Dialog isOpen={!isVerificationModalOpen}>
        <div className="flex flex-col items-center gap-4 p-4">
          <div className="flex flex-col items-center gap-6">
            <img src={fullyRampedLogo} alt="FullyRamped Logo" className="h-14 w-14" />
            <Typography size={TypographySize.H2} weight={TypographyWeight.SEMI_BOLD}>
              Finish setting up your account
            </Typography>
          </div>
          <div className="mb-4 flex w-full items-center gap-4">
            <Avatar size={ComponentSize.LARGE} label={user.name} imgSrc={user.picture} />
            <div className="flex flex-grow gap-2">
              <TextInput
                size={ComponentSize.MEDIUM}
                className="flex-grow"
                value={firstName}
                disabled
                placeholder="First name"
              />
              <TextInput
                size={ComponentSize.MEDIUM}
                className="flex-grow"
                value={lastName}
                disabled
                placeholder="Last name"
              />
            </div>
          </div>
          <div className="flex flex-col items-center gap-4">
            <Typography alignment={Alignment.CENTER} size={TypographySize.H5}>
              Add the primary phone number you will use to make practice calls. You can add more later.
            </Typography>
            <PhoneNumberInput
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              size={ComponentSize.MEDIUM}
              error={!!phoneNumberError.length}
              message={
                callEnded ? { text: CANCELLED_VERIFICATION_ERROR_MSG } : { text: phoneNumberError, destructive: true }
              }
              resetError={resetError}
              isLoading={isVerifying || isOnboarding}
              onKeyDown={(e) => {
                // Pressing enter while the phone number is valid will start the verification process.
                if (e.key === 'Enter' && isVerifyEnabled) {
                  onVerify();
                }
              }}
            />
          </div>
          <div className="flex w-full flex-col items-end gap-4">
            <div className="flex w-full flex-col gap-2">
              <TextButton
                text="Verify"
                fullWidth
                onClick={onVerify}
                size={ComponentSize.MEDIUM}
                loading={isVerifying}
                disabled={isOnboarding || !isVerifyEnabled}
              />
              {canOnboardUser && (
                <TextButton
                  text="Skip for now"
                  fullWidth
                  onClick={handleOnboardUser}
                  size={ComponentSize.MEDIUM}
                  variant={ButtonVariant.OUTLINE}
                  color={ButtonColor.SECONDARY}
                  disabled={isVerifying}
                  loading={isOnboarding}
                />
              )}
            </div>
            <Typography color={TextColor.LINK} onClick={!isVerifying && !isOnboarding ? logout : undefined} underline>
              Logout
            </Typography>
          </div>
        </div>
      </Dialog>
      <PhoneVerificationModal
        setError={setPhoneNumberError}
        userId={user.id}
        phoneNumber={fullPhoneNumber}
        verificationCode={verificationCode}
        onSuccess={handleOnboardUser}
        showLogo
        setCallEnded={setCallEnded}
        resetVerificationCode={() => setVerificationCode('')}
      />
    </div>
  );
};

export default FinishSetupPage;

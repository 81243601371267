import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { ComponentSize, TagColor } from '../../../types';
import { conditionalObject, convertToPx } from '../../../utils';
import { BadgeDot } from '../BadgeDot';
import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip';
import { BADGE_SIZE } from './Badge.constants';
import { BadgeColor, BadgeProps } from './Badge.types';

const Badge = ({
  label,
  color = BadgeColor.DEFAULT,
  dotColor = TagColor.BLUE,
  outline = false,
  showDot,
  maxWidth,
  size = ComponentSize.SMALL,
  onClick,
}: BadgeProps) => {
  const labelRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (labelRef.current) {
      setIsOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth);
    }
  }, [label, maxWidth]);

  return (
    <Tooltip>
      <TooltipTrigger>
        <div
          className={clsx(
            'badge flex items-center justify-center gap-2',
            outline && 'badge-outline',
            onClick && 'cursor-pointer',
            color,
            BADGE_SIZE[size]
          )}
          style={{ maxWidth: convertToPx(maxWidth) }}
          onClick={onClick}
        >
          {showDot && <BadgeDot color={dotColor} />}
          <div
            ref={labelRef}
            className={clsx(!outline && color === BadgeColor.NEUTRAL && 'text-white')}
            style={{
              ...conditionalObject(!!maxWidth, {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }),
            }}
          >
            {label}
          </div>
        </div>
      </TooltipTrigger>
      {/* Show tooltip only when the label is overflowing */}
      {isOverflowing && <TooltipContent>{label}</TooltipContent>}
    </Tooltip>
  );
};

export default Badge;

import clsx from 'clsx';
import { TextColor } from '../../../types';
import { ButtonColor, ButtonVariant, IconButton } from '../Button';
import { Icon } from '../Icons';
import { Portal } from '../Portal';
import { Typography, TypographySize, TypographyWeight } from '../Typography';
import { DIALOG_TYPE_TO_WIDTH } from './Dialog.constants';
import { DialogProps, DialogType } from './Dialog.types';

const Dialog = ({
  children,
  isOpen,
  description,
  hideCloseButton,
  title,
  type = DialogType.DEFAULT,
  onClose,
  footer,
  className,
}: DialogProps) => {
  return (
    <Portal>
      <dialog className="modal" open={isOpen} onClose={onClose}>
        <div
          className={clsx(
            'modal-box flex max-w-none flex-col gap-4 rounded-lg bg-base-0 p-8 shadow-dialog',
            DIALOG_TYPE_TO_WIDTH[type],
            className
          )}
        >
          {!hideCloseButton && onClose && (
            <div className="absolute right-2 top-2">
              <IconButton
                onClick={onClose}
                icon={Icon.CLOSE}
                variant={ButtonVariant.GHOST}
                color={ButtonColor.SECONDARY}
              />
            </div>
          )}
          <Typography weight={TypographyWeight.SEMI_BOLD} size={TypographySize.H2}>
            {title}
          </Typography>
          {description && (
            <Typography color={TextColor.SECONDARY} size={TypographySize.H5}>
              {description}
            </Typography>
          )}
          <div className="flex-grow overflow-auto">{children}</div>
          {footer && <div className="flex-grow overflow-auto">{footer}</div>}
        </div>
      </dialog>
    </Portal>
  );
};

export default Dialog;

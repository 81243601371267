import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import WaveSurfer from 'wavesurfer.js';
import { CALL_START_TIME_QUERY_PARAM } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setCurrentCallDetails } from '../../redux/reducers';

/**
 * Custom hook to handle seeking within a WaveSurfer instance based on query parameters
 * or Redux state. It supports seeking to a specific start time from a URL query parameter
 * or a Redux state value, and optionally playing the audio after seeking.
 */
const useMediaPlayerSeek = (
  callSid: string,
  duration: number,
  waveSurfer: React.MutableRefObject<WaveSurfer | null>
) => {
  const location = useLocation();

  const dispatch = useAppDispatch();
  const { autoPlay, startTime } = useAppSelector((state) => state.callHistory.currentCallDetails) || {};

  // Update Redux with the query startTime if it exists.
  // This is done when sharing a link with a specific start time.
  useEffect(() => {
    if (!waveSurfer.current) return;

    const queryParams = new URLSearchParams(location.search);
    const queryStartTime = queryParams.get(CALL_START_TIME_QUERY_PARAM);
    if (!queryStartTime) return;

    // We do not enable auto-playing the audio to allow the user
    // to manually start playback if desired.
    dispatch(setCurrentCallDetails({ callSid, startTime: parseFloat(queryStartTime) }));
    window.history.replaceState({}, '', location.pathname);
  }, [location.pathname, waveSurfer.current]);

  // Seek to the Redux reduxStartTime if it exists.
  useEffect(() => {
    if (!waveSurfer.current || !startTime || !duration) return;

    try {
      waveSurfer.current.seekTo(startTime / duration);
      if (autoPlay) {
        waveSurfer.current?.play();
      }

      // Reset Redux state after seeking.
      dispatch(setCurrentCallDetails({ callSid }));
    } catch (error) {
      console.error('Error seeking to desired time:', error);
    }
  }, [callSid, dispatch, duration, startTime]);
};

export default useMediaPlayerSeek;

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../constants';
import { CallHistoryFilterKeys, CallHistoryFilters, PracticeFilterKeys, PracticeFilters, Roles } from '../types';
import useAppSelector from './useAppSelector';

/**
 * Extracts all key-value pairs from the given URLSearchParams object.
 * Split comma-separated values into arrays for each parameter.
 * For the search parameter, we don't split the value into an array.
 */
const getAllParams = (params: URLSearchParams): Record<string, string | string[]> => {
  const entries: Record<string, string | string[]> = {};
  params.forEach((value, key) => {
    entries[key] = key === PracticeFilterKeys.SEARCH ? value : value.split(',');
  });
  return entries;
};

const useGetFiltersFromParams = () => {
  const location = useLocation();

  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const isCallHistoryPage = useMemo(() => location.pathname.includes(AppRoutes.CALL_HISTORY), [location.pathname]);

  const user = useAppSelector((state) => state.auth.user);
  const isSalesRep = useMemo(() => user?.role === Roles.SALES_REP, [user?.role]);

  const filters = useMemo(() => {
    // Get all parameters from the URL search params.
    const allParams = getAllParams(params);

    // Remove the 'caller' filter for sales reps.
    if (isCallHistoryPage && isSalesRep) {
      delete allParams[CallHistoryFilterKeys.CALLER];
    }

    return allParams as PracticeFilters | CallHistoryFilters;
  }, [params, isCallHistoryPage, isSalesRep]);

  return filters;
};

export default useGetFiltersFromParams;

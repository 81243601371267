import { useAuth0 } from '@auth0/auth0-react';
import { FullStory } from '@fullstory/browser';
import { jwtDecode } from 'jwt-decode';
import { useCallback, useEffect } from 'react';
import { JWT_NAMESPACE_APP_METADATA_CLAIM, JWT_NAMESPACE_ORG_CLAIM, JWT_NAMESPACE_ROLES_CLAIM } from '../constants';
import {
  logoutUser,
  setIsAuthenticated,
  setIsLoading,
  setIsOnboarded,
  setOrganization,
  setUser,
} from '../redux/reducers';
import { Auth0AppMetaData, Auth0JwtClaims, Auth0Organization, Permissions, Roles } from '../types';
import { getParsedAuth0User, transformAuth0Role } from '../utils';
import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';
import { useGetOrganizationQuery } from '../services';

/**
 * Decodes an Auth0 access token to extract organization, roles, and onboarding status.
 */
const decodeAccessToken = (accessToken: string) => {
  // Decode the JWT access token to extract claims.
  const decoded: Auth0JwtClaims = jwtDecode(accessToken);

  // Extract the organization details from the decoded token.
  const org: Auth0Organization = decoded[JWT_NAMESPACE_ORG_CLAIM];

  // Extract application metadata, specifically the onboarding status.
  // Note: `appMetaData.onboarded` may not exist if the user is invited through Auth0 directly and not through the app.
  const appMetaData: Auth0AppMetaData = decoded[JWT_NAMESPACE_APP_METADATA_CLAIM] ?? {};
  const onboarded = appMetaData.onboarded?.[org.id] ?? false;

  // Extract permissions from the decoded token.
  const permissions = decoded.permissions;

  // Extract roles from the decoded token.
  const roles = decoded[JWT_NAMESPACE_ROLES_CLAIM];

  return { onboarded, org, permissions, roles };
};

/**
 * Custom hook that synchronizes the Auth0 authentication state with the Redux store.
 * It updates the store based on changes to the Auth0 user's authentication status.
 */
const useSyncAuthState = () => {
  const {
    error,
    isAuthenticated: auth0IsAuthenticated,
    isLoading: auth0IsLoading,
    getAccessTokenSilently,
  } = useAuth0();
  const { logout, user: auth0User } = useAuth0();

  const dispatch = useAppDispatch();
  const {
    isAuthenticated: reduxIsAuthenticated,
    isOnboarded: reduxIsOnboarded,
    organization,
  } = useAppSelector((state) => state.auth);
  const orgId = organization?.id ?? '';

  const { data: org } = useGetOrganizationQuery(orgId);

  useEffect(() => {
    if (org) {
      dispatch(setOrganization(org));
    }
  }, [org]);

  const updateOrgAndUserDetails = useCallback(
    (org: Auth0Organization, permissions: Permissions[], roles: Roles[]) => {
      if (!auth0User) return;

      // Dispatch organization and user details
      const newOrg = {
        name: org.display_name,
        id: org.id,
      };

      dispatch(setOrganization(newOrg));

      const newUser = getParsedAuth0User({
        ...auth0User,
        user_id: auth0User.sub,
        permissions,
        roles,
      });

      dispatch(setUser(newUser));
    },
    [auth0User, dispatch]
  );

  // Identify the user in FullStory
  useEffect(() => {
    if (auth0IsLoading || !process.env.REACT_APP_FULLSTORY_ORG_ID) return;

    if (auth0User) {
      FullStory('setIdentity', {
        uid: auth0User.sub,
        properties: {
          displayName: auth0User.name,
          email: auth0User.email,
          orgId: auth0User.orgId,
          orgName: auth0User.orgName,
        },
      });
    } else {
      FullStory('setIdentity', { anonymous: true });
    }
  }, [auth0User, auth0IsLoading]);

  // Function to fetch and dispatch the new access token if it has changed.
  const refreshAuthState = useCallback(async () => {
    try {
      const auth0AccessToken = await getAccessTokenSilently();
      const {
        onboarded: auth0IsOnboarded,
        org: auth0Org,
        permissions: auth0Permissions,
        roles: auth0Roles,
      } = decodeAccessToken(auth0AccessToken);

      if (!reduxIsOnboarded && auth0IsOnboarded) {
        dispatch(setIsOnboarded(auth0IsOnboarded));
      }

      if (!reduxIsAuthenticated) {
        dispatch(setIsAuthenticated(true));
      }

      updateOrgAndUserDetails(auth0Org, auth0Permissions, auth0Roles.map(transformAuth0Role));
    } catch (e) {
      console.error('Failed to get access token:', e);
      logout();
    } finally {
      dispatch(setIsLoading(false));
    }
  }, [dispatch, getAccessTokenSilently, logout, updateOrgAndUserDetails]);

  // Updates the Redux authentication state based on Auth0 changes.
  useEffect(() => {
    dispatch(setIsLoading(true));

    if (auth0IsLoading) return;

    if (!auth0IsAuthenticated && !error) {
      dispatch(logoutUser());
      return;
    }

    if (auth0IsAuthenticated) {
      refreshAuthState();
    } else {
      dispatch(setIsLoading(false));
    }
  }, [dispatch, auth0IsAuthenticated, auth0IsLoading, error, refreshAuthState]);
};

export default useSyncAuthState;

import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import fullyRampedLogo from '../../assets/logo-light.png';
import { AppRoutes } from '../../constants';
import { useAppDispatch, useAppSelector, useFeatureFlag } from '../../hooks';
import { SettingsModalTabs, openSettingsModal, openUploadModal } from '../../redux/reducers';
import { LD_FeatureFlags, Permissions, TextColor } from '../../types';
import { SettingsModal, UploadModal } from '../modals';
import {
  Avatar,
  ButtonColor,
  ButtonVariant,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
  Icon,
  IconButton,
  TextButton,
  Typography,
  TypographyWeight,
} from '../shared';

const NavBar = () => {
  // Hooks
  const { logout } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const { isHiringModuleEnabled, isUploadModalEnabled } = useAppSelector((state) => state.auth.organization) || {};
  const uploadModalFF = useFeatureFlag(LD_FeatureFlags.UPLOAD_MODAL);

  // Redux
  const dispatch = useAppDispatch();
  const { organization, user } = useAppSelector((state) => state.auth);

  // Check if the curr user is allowed to invite other users.
  const permissions = user?.permissions || [];
  const userCanInvite = permissions.includes(Permissions.INVITE_USER);

  // State
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  // Navigation items
  const items = [
    {
      icon: Icon.PHONE,
      title: 'Practice',
      route: AppRoutes.PRACTICE,
    },
    {
      icon: Icon.HISTORY,
      title: 'Call History',
      route: AppRoutes.CALL_HISTORY,
    },
  ];

  return (
    <>
      <div className="navbar z-10 flex w-full justify-between border-b border-b-neutral-200 bg-base-0 px-4 py-2 shadow-sm">
        <div className="flex items-center gap-3">
          <img src={fullyRampedLogo} alt="FullyRamped Logo" className="h-8 w-8 rounded-md" />
          {items.map(({ title, icon, route }) => (
            <TextButton
              key={route}
              text={title}
              startIcon={icon}
              onClick={route ? () => navigate(route) : undefined}
              active={location.pathname.includes(route)}
              variant={ButtonVariant.FILLED}
              color={ButtonColor.SECONDARY}
            />
          ))}
        </div>
        <div className="flex items-center gap-3">
          {isUploadModalEnabled && uploadModalFF && (
            <IconButton
              icon={Icon.UPLOAD}
              onClick={() => {
                dispatch(openUploadModal());
              }}
              tooltip="Upload file"
              color={ButtonColor.SECONDARY}
            />
          )}
          {userCanInvite && (
            <IconButton
              icon={Icon.USER_PLUS}
              onClick={() => {
                dispatch(openSettingsModal(SettingsModalTabs.ORGANIZATION));
              }}
              tooltip="Invite user"
              color={ButtonColor.SECONDARY}
            />
          )}
          <Dropdown open={isUserModalOpen} onOpenChange={setIsUserModalOpen}>
            <DropdownTrigger>
              <Avatar label={user?.name} imgSrc={user?.picture} />
            </DropdownTrigger>
            <DropdownContent>
              <DropdownItem
                label={
                  <Typography weight={TypographyWeight.SEMI_BOLD} color={TextColor.PRIMARY}>
                    {organization?.name}
                  </Typography>
                }
              />
              <DropdownItem
                label="Account setup"
                icon={Icon.SETTINGS}
                onClick={() => {
                  dispatch(openSettingsModal(SettingsModalTabs.PROFILE));
                  setIsUserModalOpen(false);
                }}
              />
              <DropdownItem
                label="Organization"
                icon={Icon.ORGANIZATION}
                onClick={() => {
                  dispatch(openSettingsModal(SettingsModalTabs.ORGANIZATION));
                  setIsUserModalOpen(false);
                }}
              />
              {permissions.includes(Permissions.VIEW_CANDIDATE) && (
                <DropdownItem
                  label="Hiring"
                  icon={isHiringModuleEnabled ? Icon.HANDSHAKE : Icon.LOCK}
                  disabled={!isHiringModuleEnabled}
                  onClick={() => {
                    dispatch(openSettingsModal(SettingsModalTabs.HIRING));
                    setIsUserModalOpen(false);
                  }}
                />
              )}
              <DropdownItem label="Logout" icon={Icon.LOG_OUT} onClick={logout} />
            </DropdownContent>
          </Dropdown>
        </div>
      </div>
      {/* Navbar Modals */}
      <SettingsModal />
      <UploadModal />
    </>
  );
};

export default NavBar;

import { MousePosition } from '../../../types';

export enum DropdownPlacement {
  BOTTOM = 'bottom',
  TOP = 'top',
  RIGHT = 'right',
  LEFT = 'left',
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',
  TOP_START = 'top-start',
  TOP_END = 'top-end',
  RIGHT_START = 'right-start',
  RIGHT_END = 'right-end',
  LEFT_START = 'left-start',
  LEFT_END = 'left-end',
}

export interface DropdownOptions {
  children?: React.ReactNode;
  // Whether the dropdown trigger is disabled.
  disabled?: boolean;
  // Whether the dropdown should take the full width of its reference.
  fullWidth?: boolean;
  // Whether the dropdown is initially open.
  initialOpen?: boolean;
  // Optional list navigation props.
  // Enables keyboard navigation of dropdown items.
  listNavigation?: {
    // Reference to the list of dropdown items
    listRef: React.MutableRefObject<(HTMLElement | null)[]>;
    // Index of the currently active item
    activeIndex: number | null;
    // Callback function when the active item changes
    setActiveIndex: (index: number | null) => void;
  };
  // Optional max dropdown content height.
  maxHeight?: string | number;
  // Optional max dropdown content width.
  maxWidth?: string | number;
  // Optional min dropdown content width.
  minWidth?: string | number;
  // Whether the dropdown should behave as a modal.
  modal?: boolean;
  // Offset of the dropdown relative to its reference.
  offset?: number;
  // Controlled open state of the dropdown.
  open?: boolean;
  // Placement of the dropdown relative to its reference.
  placement?: DropdownPlacement;
  // Custom position for the dropdown.
  position?: MousePosition;
  // Optional arrow for the dropdown.
  showArrow?: boolean;
  // Callback when the open state changes.
  onOpenChange?: (open: boolean) => void;
}

import React from 'react';
import { Icon, Icons, Typography, TypographySize, TypographyWeight } from '../../components';
import { TextColor } from '../../types';

const UnderConstructionPage: React.FC = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4 bg-base-0">
      <Icons icon={Icon.TRAFFIC_CONE} size={64} />
      <Typography size={TypographySize.H1} weight={TypographyWeight.SEMI_BOLD}>
        Routine maintenance
      </Typography>
      <Typography size={TypographySize.H2} weight={TypographyWeight.SEMI_BOLD} color={TextColor.SECONDARY}>
        Building new features just for you! Please try again in a few hours.
      </Typography>
    </div>
  );
};

export default UnderConstructionPage;

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants';
import { useAppDispatch, useFeatureFlag } from '../../hooks';
import { setCurrentCallDetails } from '../../redux/reducers';
import { CallProcessingStatus, ComponentSize, LD_FeatureFlags } from '../../types';
import { ButtonColor, ButtonVariant, Icon, IconButton } from '../shared';

interface ControlButtonsProps {
  callSid: string;
  processingStatus?: CallProcessingStatus;
}

const ControlButtons = ({ callSid, processingStatus }: ControlButtonsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const detailedMediaPlayerViewFF = useFeatureFlag(LD_FeatureFlags.DETAILED_MEDIA_PLAYER_VIEW);

  // Handles opening call details.
  const openDetails = useCallback(() => navigate(`${AppRoutes.CALL_HISTORY}/${callSid}`), [callSid]);

  // Handles closing the modal by clearing the callSid in Redux.
  const closeModal = useCallback(() => {
    dispatch(setCurrentCallDetails(undefined));
  }, [dispatch]);

  return (
    <div className="absolute right-6 top-2 flex gap-1">
      {/* Only show the open details button if call processing has completed. */}
      {detailedMediaPlayerViewFF && processingStatus === CallProcessingStatus.PROCESSED && (
        <IconButton
          icon={Icon.OPEN}
          size={ComponentSize.X_SMALL}
          variant={ButtonVariant.GHOST}
          color={ButtonColor.SECONDARY}
          onClick={openDetails}
          tooltip="Open details"
        />
      )}
      <IconButton
        icon={Icon.CLOSE}
        size={ComponentSize.X_SMALL}
        variant={ButtonVariant.GHOST}
        color={ButtonColor.SECONDARY}
        onClick={closeModal}
      />
    </div>
  );
};

export default ControlButtons;

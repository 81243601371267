import { ComponentSize, TextColor } from '../../../types';
import { Avatar } from '../Avatar';
import { Typography } from '../Typography';
import { USER_BADGE_SUBTITLE_SIZE, USER_BADGE_TITLE_SIZE } from './UserBadge.constants';
import { UserBadgeProps } from './UserBadge.types';

const UserBadge = ({
  title,
  imgSrc,
  isInvitee,
  size = ComponentSize.SMALL,
  subtitle,
  hideAvatarIfNoImgSrc,
}: UserBadgeProps) => {
  return (
    <div className="flex items-center gap-2">
      {(!hideAvatarIfNoImgSrc || imgSrc) && <Avatar label={title} size={size} imgSrc={imgSrc} />}
      <div className="flex flex-col gap-1">
        <div className="flex gap-2">
          <Typography size={USER_BADGE_TITLE_SIZE[size]}>{title}</Typography>
          {isInvitee && <Typography color={TextColor.SECONDARY}>Invited</Typography>}
        </div>
        {subtitle && (
          <Typography size={USER_BADGE_SUBTITLE_SIZE[size]} color={TextColor.SECONDARY}>
            {subtitle}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default UserBadge;

import clsx from 'clsx';
import { useRef, useState } from 'react';
import { ComponentSize, TextColor } from '../../../types';
import { Typography, TypographySize } from '../Typography';
import { ButtonColor, TextButton } from '../Button';
import { INPUT_SIZE, TextInput, TextInputType } from '../TextInput';
import {
  COMPONENT_SIZE_TO_TYPOGRAPHY_SIZE,
  DEFAULT_FILE_INPUT_PLACEHOLDER,
  DEFAULT_MAX_FILE_SIZE,
} from './FileInput.constants';
import { FileInputProps } from './FileInput.types';

const FileInput = ({
  disabled,
  size = ComponentSize.SMALL,
  buttonText = 'Browse',
  selectedFile,
  setSelectedFile,
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
}: FileInputProps) => {
  // State to store error message
  const [error, setError] = useState<string>('');

  // Ref to the native file input element
  const inputRef = useRef<HTMLInputElement>(null);

  // Handler function to manage file selection
  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const resetState = () => {
    // clear selected file and error
    setSelectedFile(undefined);
    setError('');
  };

  // Handler function to manage file selection
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      // Validate file size
      if (file.size > maxFileSize) {
        // Set error message if file size exceeds 50MB
        setError('File size exceeds the maximum limit of 50 MB');
        // Reset selected file
        setSelectedFile(undefined);
      } else {
        // Clear error message if file size is within the limit
        setError('');
        // Update state with the selected file
        setSelectedFile(file);
      }
    } else {
      resetState();
    }
  };

  const inputTextColor = selectedFile ? TextColor.PRIMARY : TextColor.TERTIARY;

  return (
    <div className="flex w-full flex-col">
      <div className="flex  w-full">
        {/* Custom button to trigger file input */}
        <TextButton
          size={size}
          disabled={disabled}
          text={buttonText}
          onClick={handleClick}
          color={ButtonColor.INVERTED}
          className="rounded-r-none"
        />
        <div
          className={clsx(
            'p-2-0 input input-bordered flex w-full cursor-pointer items-center rounded-l-none border-base-300 bg-base-0 !outline-none',
            INPUT_SIZE[size],
            disabled && 'input-disabled cursor-auto border-transparent bg-base-100 text-neutral',
            error && '!border-error-content bg-error'
          )}
          onClick={handleClick}
        >
          {/* Display the selected file name or "No file chosen" */}
          <Typography color={inputTextColor} size={COMPONENT_SIZE_TO_TYPOGRAPHY_SIZE[size]}>
            {selectedFile?.name ?? DEFAULT_FILE_INPUT_PLACEHOLDER}
          </Typography>

          {/* Hidden native file input, accepts any file type */}
          <TextInput type={TextInputType.FILE} ref={inputRef} onChange={handleFileChange} className="hidden" />
        </div>
      </div>

      <div className="h-4">
        <Typography size={TypographySize.CAPTION} color={TextColor.DESTRUCTIVE}>
          {error}
        </Typography>
      </div>
    </div>
  );
};

export default FileInput;

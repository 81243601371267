// Error messages
export const CANCELLED_VERIFICATION_ERROR_MSG = 'Cancelled without verification';
export const VERIFICATION_FAILED_ERROR_MSG = 'Cannot verify phone number';

// Events
export const JOIN_ROOM_EVENT = 'joinRoom';
export const VERIFICATION_SUCCESS_EVENT = 'verification-success';
export const VERIFICATION_FAILED_EVENT = 'verification-failed';

// Country code for US
export const US_COUNTRY_CODE = '+1';

import clsx from 'clsx';
import { Alignment, TextColor } from '../../../types';
import { getTextColorClass } from '../../../utils';
import { TYPOGRAPHY_ALIGNMENT, TYPOGRAPHY_SIZE, TYPOGRAPHY_WEIGHT } from './Typography.constants';
import { TypographyProps, TypographySize, TypographyWeight } from './Typography.types';

const Typography = ({
  children,
  alignment = Alignment.LEFT,
  className,
  color = TextColor.PRIMARY,
  size = TypographySize.PARAGRAPH,
  underline,
  weight = TypographyWeight.NORMAL,
  onClick,
}: TypographyProps) => {
  return (
    <span
      className={clsx(
        getTextColorClass(color),
        TYPOGRAPHY_ALIGNMENT[alignment],
        TYPOGRAPHY_SIZE[size],
        TYPOGRAPHY_WEIGHT[weight],
        underline && 'underline',
        onClick && 'cursor-pointer',
        className
      )}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export default Typography;

import { Dispatch, SetStateAction, useCallback } from 'react';
import { useHandleApiResponse, useToast } from '../../../../../hooks';
import { useRevokeInvitationMutation } from '../../../../../services';
import { AlertType, Typography, TypographyWeight } from '../../../../shared';
import ConfirmModal from '../../../ConfirmModal';

const ERROR_MSG = 'Failed to revoke invitation';

interface RevokeConfirmModalProps {
  isOpen: boolean;
  user: { id: string; name?: string };
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const RevokeConfirmModal = ({ isOpen, user, setIsOpen }: RevokeConfirmModalProps) => {
  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  const [revokeInvitation, { isLoading }] = useRevokeInvitationMutation();

  // Handles revoking an invitation.
  const handleRevokeInvitation = useCallback(async () => {
    try {
      const response = await revokeInvitation({ id: user.id });
      const onSuccess = () => setIsOpen(false);
      handleApiResponse({ response, errorMsg: ERROR_MSG, onSuccess });
    } catch (error) {
      console.error(`${ERROR_MSG}: `, error);
      showToast({ message: ERROR_MSG, type: AlertType.ERROR });
    }
  }, [handleApiResponse, showToast]);

  return (
    <ConfirmModal
      buttonText="Revoke"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onConfirm={handleRevokeInvitation}
      isLoading={isLoading}
      title="Confirm revoke invitation"
      destructive
      confirmText={
        <>
          Are you sure you want revoke the invitation sent to{' '}
          <Typography weight={TypographyWeight.MEDIUM}>{user.name}</Typography>?
        </>
      }
    />
  );
};

export default RevokeConfirmModal;

import clsx from 'clsx';
import React, { forwardRef, useCallback, useEffect } from 'react';
import { ComponentSize, TextColor } from '../../../types';
import { Icon, Icons } from '../Icons';
import { Typography, TypographySize } from '../Typography';
import { DropdownItemProps } from './DropdownItem.types';

const DropdownItem = forwardRef<HTMLLIElement, DropdownItemProps>(
  ({ label, active, destructive, disabled, hovered, icon, startElement, onClick }, ref) => {
    const textSize = TypographySize.CAPTION;
    const shouldDisable = disabled || !onClick;

    const handleClick = onClick
      ? (e: React.MouseEvent<HTMLLIElement>) => {
          if (onClick) {
            e.stopPropagation();
            onClick();
          }
        }
      : undefined;

    const getTextColor = useCallback(() => {
      if (shouldDisable) return TextColor.TERTIARY;
      if (destructive) return TextColor.DESTRUCTIVE;
      return TextColor.SECONDARY;
    }, [destructive, shouldDisable]);

    const renderIcon = useCallback(
      (icon: Icon) => <Icons icon={icon} color={getTextColor()} size={ComponentSize.X_SMALL} />,
      [icon, getTextColor]
    );

    // Handles triggering the onClick event when the Enter key is pressed and the item is hovered.
    useEffect(() => {
      if (!hovered || !onClick) return;

      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          onClick();
        }
      };

      document.addEventListener('keydown', handleKeyDown);
      return () => document.removeEventListener('keydown', handleKeyDown);
    }, [onClick, hovered]);

    return (
      <li onClick={handleClick} className={clsx(shouldDisable && 'pointer-events-none')} ref={ref}>
        <div
          className={clsx(
            'flex justify-between p-2',
            hovered && (destructive ? 'bg-error' : 'bg-base-100'),
            destructive ? 'hover:bg-error' : 'hover:bg-base-100'
          )}
        >
          <div className="flex items-center gap-2">
            {startElement && startElement}
            {icon && renderIcon(icon)}
            {typeof label === 'string' && (
              <Typography color={getTextColor()} size={textSize}>
                {label}
              </Typography>
            )}
            {typeof label === 'object' &&
              React.cloneElement(label, {
                color: label.props.color ?? getTextColor(),
                size: label.props.size ?? textSize,
              })}
          </div>
          {active && renderIcon(Icon.CHECK)}
        </div>
      </li>
    );
  }
);

DropdownItem.displayName = 'DropdownItem';

export default DropdownItem;

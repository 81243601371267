import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AppRoutes } from '../../constants';
import {
  CallDetailsPage,
  CallHistoryPage,
  FinishSetupPage,
  LayoutPage,
  NotFoundPage,
  PracticePage,
  UnderConstructionPage,
} from '../../pages';
import ProtectedRoute from './ProtectedRoute';
import { useFeatureFlag } from '../../hooks';
import { LD_FeatureFlags } from '../../types';

// Router configuration using pre-defined route constants.
const router = createBrowserRouter([
  {
    // Protected route that renders the main layout if the user is authenticated.
    path: AppRoutes.HOME,
    element: (
      <ProtectedRoute>
        <LayoutPage />
      </ProtectedRoute>
    ),
    children: [
      {
        // Redirect root path to Practice page.
        path: AppRoutes.HOME,
        element: <Navigate to={AppRoutes.PRACTICE} replace />,
      },
      {
        // Route for the Practice page.
        path: AppRoutes.PRACTICE,
        element: <PracticePage />,
      },
      {
        // Route for the Call History page.
        path: AppRoutes.CALL_HISTORY,
        element: <CallHistoryPage />,
      },
      {
        path: `${AppRoutes.CALL_HISTORY}/:callSid`,
        element: <CallDetailsPage />,
      },
    ],
  },
  {
    path: AppRoutes.FINISH_SETUP,
    element: (
      <ProtectedRoute>
        <FinishSetupPage />
      </ProtectedRoute>
    ),
  },
  {
    // Route for handling 404 not found errors.
    path: AppRoutes.NOT_FOUND,
    element: <NotFoundPage />,
  },
]);

/** Provides router context to the application. */
const CustomRouterProvider: React.FC = () => {
  const isUnderConstruction = useFeatureFlag(LD_FeatureFlags.UNDER_CONSTRUCTION);

  if (isUnderConstruction) {
    return <UnderConstructionPage />;
  }

  return <RouterProvider router={router} />;
};

export default CustomRouterProvider;

import { DateOptions } from '../../../types';
import { parseRequiredSelectOption } from '../../../utils';
import { SelectOption } from '../../shared';
import SidebarSelectFilter from './SidebarSelectFilter';

interface SidebarDateFilterProps {
  title: string;
  onChange: (newValue?: string[]) => void;
  selected?: SelectOption[];
}

const SidebarDateFilter = ({ ...selectProps }: SidebarDateFilterProps) => {
  return (
    <SidebarSelectFilter
      options={Object.values(DateOptions).map(parseRequiredSelectOption)}
      placeholder="Select date range"
      {...selectProps}
    />
  );
};

export default SidebarDateFilter;

import { AsYouType, CountryCode, getCountries, getCountryCallingCode } from 'libphonenumber-js';
import { US_COUNTRY_CODE } from '../constants';
import { formatUSPhoneNumber } from './string.utils';

/** Validate a country code input. */
export function isValidCountryCode(newCode: string): boolean {
  if (newCode === '+') return true;
  if (!Number(newCode)) return false; // Only allow number inputs.
  return newCode.length >= 1 && newCode.length <= 4; // Allow country codes with a maximum of 3 digits.
}

/** Get the country code (e.g., 'US') from the phone number prefix. */
export function getCountryCode(prefix: string): CountryCode | undefined {
  const countries = getCountries();
  for (const country of countries) {
    if (`+${getCountryCallingCode(country)}` === prefix) {
      return country as CountryCode;
    }
  }
  return undefined;
}

/**  Format the raw phone number for display using libphonenumber-js. */
export const formatInputPhoneNumber = (value: string, countryCode: string): string => {
  const country = getCountryCode(countryCode);
  if (!country) return value;

  // Create a new AsYouType object for formatting the phone number
  const asYouType = new AsYouType();
  // Combine the country code and the phone number
  // In order for correct formatting, we need to pass country code
  const internationalNumber = countryCode + value;
  //  Format number as you type input
  const formattedNumber = asYouType.input(internationalNumber);
  // Remove country code from input after formatting
  const nationalNumber = formattedNumber.replace(countryCode, '');
  // If the country code is US, format number as (XXX) XXX-XXXX
  if (countryCode === US_COUNTRY_CODE) return formatUSPhoneNumber(nationalNumber);

  return nationalNumber;
};

/** Function to remove non-numeric characters from the phone number*/
export const stripPhoneNumber = (phoneNumber: string) => phoneNumber.replace(/\D/g, '');

import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { ComponentSize } from '../../../../types';
import { Icons } from '../../Icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../Tooltip';
import { BUTTON_SIZE } from '../Button.constants';
import '../Button.css';
import { ButtonColor, ButtonVariant } from '../Button.types';
import { getButtonClass } from '../Button.utils';
import ButtonContent from '../ButtonContent';
import { IconButtonProps } from './IconButton.types';

const IconButton = ({
  active = false,
  icon,
  color = ButtonColor.PRIMARY,
  disabled = false,
  loading,
  size = ComponentSize.SMALL,
  tooltip,
  variant = ButtonVariant.FILLED,
  onClick,
}: IconButtonProps) => {
  const [hover, setHover] = useState(false);

  const shouldDisable = disabled || loading || !onClick;

  const buttonClass = useMemo(
    () => getButtonClass({ color, isActive: active, isDisabled: shouldDisable, isHovered: hover, variant }),
    [active, color, hover, shouldDisable, variant]
  );

  const handleOnClick = onClick
    ? (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        onClick(e);
      }
    : undefined;

  return (
    <Tooltip>
      <TooltipTrigger>
        <div
          className={clsx('btn btn-square relative', BUTTON_SIZE[size], buttonClass.bg)}
          onClick={handleOnClick}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <ButtonContent loading={loading}>
            <Icons icon={icon} color={buttonClass.text} size={size} />
          </ButtonContent>
        </div>
      </TooltipTrigger>
      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
};

export default IconButton;

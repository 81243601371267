import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { ComponentSize } from '../../../../types';
import { Icon, Icons } from '../../Icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../Tooltip';
import { Typography } from '../../Typography';
import { BUTTON_SIZE } from '../Button.constants';
import '../Button.css';
import { ButtonColor, ButtonVariant } from '../Button.types';
import { getButtonClass } from '../Button.utils';
import ButtonContent from '../ButtonContent';
import { TextButtonProps } from './TextButton.types';

const TextButton = ({
  className,
  active = false,
  text,
  color = ButtonColor.PRIMARY,
  disabled = false,
  fullWidth = false,
  endIcon,
  startIcon,
  loading,
  size = ComponentSize.SMALL,
  tooltip,
  variant = ButtonVariant.FILLED,
  onClick,
}: TextButtonProps) => {
  const [hover, setHover] = useState(false);

  const shouldDisable = disabled || loading || !onClick;

  const buttonClass = useMemo(
    () => getButtonClass({ color, isActive: active, isDisabled: shouldDisable, isHovered: hover, variant }),
    [active, color, hover, shouldDisable, variant]
  );

  const handleOnClick = onClick
    ? (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        onClick(e);
      }
    : undefined;

  const renderIcon = useCallback(
    (icon: Icon) => <Icons icon={icon} color={buttonClass.text} size={size} />,
    [buttonClass.text, size]
  );

  return (
    <Tooltip>
      <TooltipTrigger fullWidth={fullWidth}>
        <div
          className={clsx(
            'btn relative',
            fullWidth ? 'btn-block' : 'w-fit',
            BUTTON_SIZE[size],
            buttonClass.bg,
            className
          )}
          onClick={handleOnClick}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <ButtonContent loading={loading}>
            {startIcon && renderIcon(startIcon)}
            <Typography color={buttonClass.text}>{text}</Typography>
            {endIcon && renderIcon(endIcon)}
          </ButtonContent>
        </div>
      </TooltipTrigger>
      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
};

export default TextButton;

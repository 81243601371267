import { Icon, Icons, Typography, TypographySize, TypographyWeight } from '../../components';
import { TextColor } from '../../types';

const NoInternetConnectionPage: React.FC = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4 bg-base-0">
      <Icons icon={Icon.WIFI_OFF} size={64} />
      <Typography size={TypographySize.H1} weight={TypographyWeight.SEMI_BOLD}>
        No internet connection
      </Typography>
      <Typography size={TypographySize.H2} weight={TypographyWeight.SEMI_BOLD} color={TextColor.SECONDARY}>
        Please check your connection and refresh this page
      </Typography>
    </div>
  );
};

export default NoInternetConnectionPage;

import { Accordion, Dialog, DialogType, Icon, Icons, Typography, TypographySize } from '../../shared';
import { TextColor } from '../../../types';
import { PRIVACY_POLICY_URL, RESEARCHER_PHONE_NUMBER } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeUploadModal } from '../../../redux/reducers';

import UploadFileSection from './UploadFile';
import SubmitLinkSection from './SubmitLink';
import { useState } from 'react';
import { formatDisplayedPhoneNumber } from '../../../utils';

export const ACCORDION_TRIGGER_ICON_SIZE = 32;

const UploadModal = () => {
  // Redux
  const { isOpen } = useAppSelector((state) => state.modal.upload);
  const dispatch = useAppDispatch();

  // States
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [linkInput, setLinkInput] = useState<string>('');

  const [linkError, setLinkError] = useState<string>('');

  const [isFileSubmitted, setIsFileSubmitted] = useState<boolean>(false);
  const [isLinkSubmitted, setIsLinkSubmitted] = useState<boolean>(false);

  const handleDialogClose = () => {
    dispatch(closeUploadModal());
    // clear all file input states
    setSelectedFile(undefined);
    setIsFileSubmitted(false);
    // clear all link input states
    setLinkInput('');
    setLinkError('');
    setIsLinkSubmitted(false);
  };

  const accordionItems = [
    {
      id: 'share-by-phone',
      title: 'Share by phone',
      content: (
        <div className="mt-2 flex flex-col gap-1">
          <Typography color={TextColor.SECONDARY}>
            The fastest way to improve your practice prospects is by speaking with our researcher.
          </Typography>
          <div className="flex cursor-pointer items-center gap-1">
            <Icons icon={Icon.PHONE} color={TextColor.SECONDARY} />
            <Typography color={TextColor.SECONDARY} size={TypographySize.H5} underline>
              <a href={`tel:${RESEARCHER_PHONE_NUMBER}`} onClick={(e) => e.stopPropagation()}>
                {formatDisplayedPhoneNumber(RESEARCHER_PHONE_NUMBER)}
              </a>
            </Typography>
          </div>
        </div>
      ),
    },
    {
      id: 'submit-a-file',
      title: 'Submit a file',
      content: (
        <UploadFileSection
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          isFileSubmitted={isFileSubmitted}
          setIsFileSubmitted={setIsFileSubmitted}
        />
      ),
    },
    {
      id: 'submit-a-link',
      title: 'Submit a link',
      content: (
        <SubmitLinkSection
          linkInput={linkInput}
          setLinkInput={setLinkInput}
          linkError={linkError}
          setLinkError={setLinkError}
          isLinkSubmitted={isLinkSubmitted}
          setIsLinkSubmitted={setIsLinkSubmitted}
        />
      ),
    },
  ];

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleDialogClose}
      title="Improve your organization’s practice prospects"
      type={DialogType.SETTINGS}
      footer={
        <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
          What you upload and share is private to your workspace. It is used to create realistic practice prospects, we
          do not train AI models using this information.
          <Typography
            underline
            size={TypographySize.CAPTION}
            color={TextColor.SECONDARY}
            className="ml-1"
            onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')}
          >
            Privacy policy
          </Typography>
        </Typography>
      }
      description="Make your prospects more realistic by sharing material on your ICP, training material, past calls, or industry specific knowledge."
    >
      <Accordion className="mb-4 mt-4 !gap-0 rounded-2xl bg-base-100" items={accordionItems} />
    </Dialog>
  );
};

export default UploadModal;

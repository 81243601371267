import { useCallback, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { useShareCall } from '../../../hooks';
import { ComponentSize, TextColor } from '../../../types';
import { formatDuration } from '../../../utils';
import {
  ButtonColor,
  Checkbox,
  Dropdown,
  DropdownContent,
  DropdownPlacement,
  DropdownTrigger,
  Icon,
  Icons,
  TextButton,
  TextInput,
  Typography,
  TypographySize,
} from '../../shared';

const DROPDOWN_TEXT_INPUT_WIDTH = 300;

interface ShareButtonProps {
  callSid: string;
  currentTime: number;
  waveSurfer: WaveSurfer;
}

const ShareButton = ({ callSid, currentTime, waveSurfer }: ShareButtonProps) => {
  const [copyIcon, setCopyIcon] = useState(Icon.LINK);
  // State to control the Share dropdown open/close state.
  const [isShareDropdownOpen, setIsShareDropdownOpen] = useState(false);
  // State to control the Start Time checkbox state.
  const [isStartTimeChecked, setIsStartTimeChecked] = useState(false);

  const { shareUrl, onShare } = useShareCall(callSid, isStartTimeChecked ? currentTime : undefined);

  const toggleShareDropdown = useCallback(() => {
    if (isShareDropdownOpen) {
      // Handle closing the Share dropdown and resetting the Start Time checkbox state.
      setIsShareDropdownOpen(false);
      setIsStartTimeChecked(false);
    } else {
      // Handle opening the Share dropdown and pausing the audio if it is currently playing.
      setIsShareDropdownOpen(true);
      if (waveSurfer.isPlaying()) {
        waveSurfer.pause();
      }
    }
  }, [isShareDropdownOpen, waveSurfer, setIsShareDropdownOpen, setIsStartTimeChecked]);

  // Handles copying the Call URL to the clipboard.
  const copyUrlToClipboard = useCallback(() => {
    onShare();
    setCopyIcon(Icon.CHECK);
    setTimeout(() => {
      setCopyIcon(Icon.LINK);
    }, 2000);
  }, [onShare, setCopyIcon]);

  return (
    <Dropdown open={isShareDropdownOpen} onOpenChange={toggleShareDropdown} placement={DropdownPlacement.TOP_END}>
      <DropdownTrigger>
        <TextButton
          text="Share"
          startIcon={Icon.SHARE}
          onClick={toggleShareDropdown}
          active={isShareDropdownOpen}
          color={ButtonColor.SECONDARY}
        />
      </DropdownTrigger>
      <DropdownContent className="flex flex-col gap-2">
        <TextInput
          value={shareUrl}
          width={DROPDOWN_TEXT_INPUT_WIDTH}
          size={ComponentSize.X_SMALL}
          disabled
          endElement={
            <Icons
              icon={copyIcon}
              size={ComponentSize.X_SMALL}
              tooltip={copyIcon === Icon.LINK ? 'Copy URL to clipboard' : 'Copied!'}
              onClick={copyUrlToClipboard}
            />
          }
        />
        <div className="flex items-center gap-1">
          <Checkbox checked={isStartTimeChecked} setChecked={setIsStartTimeChecked} size={ComponentSize.X_SMALL} />
          <span>
            <Typography size={TypographySize.CAPTION}>Start at</Typography>{' '}
            <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
              {formatDuration(currentTime)}
            </Typography>
          </span>
        </div>
      </DropdownContent>
    </Dropdown>
  );
};

export default ShareButton;

import { useCallback, useState } from 'react';
import { Alert, AlertType } from '../../components';
import ToastContext, { ToastProps } from './ToastContext';

// The intercom support button is 48px in height/width and 20px from the right edge of the screen.
const INTERCOM_BUTTON_SIZE = 48;
const INTERCOM_BUTTON_RIGHT_POSITION = 20;

const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  // State to hold the list of toasts.
  const [toasts, setToasts] = useState<{ id: number; message: string; type?: AlertType }[]>([]);

  // Function to remove a toast by its id.
  const hideToast = useCallback(
    (id: number) => setToasts((toasts) => toasts.filter((toast) => toast.id !== id)),
    [setToasts]
  );

  // Function to add a new toast with a message.
  const showToast = useCallback(
    ({ message, type }: ToastProps) => {
      const id = Date.now();
      // Set a timeout to remove the toast after 3 seconds.
      setTimeout(() => hideToast(id), 3000);
      // Add the new toast to the beginning of the list.
      setToasts([{ id, message, type }, ...toasts]);
    },
    [hideToast]
  );

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {/* In order for toasts not to overlap with the intercom support button, we position toasts to the left of the button */}
      <div className="toast p-5" style={{ right: INTERCOM_BUTTON_RIGHT_POSITION + INTERCOM_BUTTON_SIZE }}>
        {toasts.map(({ id, message, type }) => (
          <Alert key={id} message={message} type={type} />
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export default ToastProvider;

import { ComponentSize, TextColor } from '../../../types';

export enum Icon {
  ARCHIVE = 'archive',
  ARROW_SORT_ASC = 'arrow-sort-ascending',
  ARROW_SORT_DESC = 'arrow-sort-descending',
  ARROW_SORT_NONE = 'arrows-sort-none',
  BAR_CHART = 'bar-chart',
  CAPTION = 'caption',
  CHECK = 'check',
  CHEVRON_DOWN = 'chevron-down',
  CHEVRON_LEFT = 'chevron-left',
  CHEVRON_RIGHT = 'chevron-right',
  CHEVRON_UP = 'chevron-up',
  CHEVRONS_UP_DOWN = 'chevrons-up-down',
  CIRCLE_ALERT = 'circle-alert',
  CIRCLE_CHECK = 'circle-check',
  CIRCLE_X = 'circle-x',
  CLOSE = 'close',
  COMMENT = 'comment',
  COPY = 'copy',
  ELLIPSIS_VERTICAL = 'ellipsis-vertical',
  FAST_FORWARD = 'fastforward',
  HANDSHAKE = 'handshake',
  HEADPHONES = 'headphones',
  HISTORY = 'history',
  INFO = 'info',
  LINK = 'link',
  LOCK = 'lock',
  LOG_OUT = 'log-out',
  MINUS = 'minus',
  OPEN = 'open',
  ORGANIZATION = 'organization',
  PAUSE = 'pause',
  PHONE = 'phone',
  PLAY = 'play',
  PLUS = 'plus',
  REWIND = 'rewind',
  SEARCH = 'search',
  SEND = 'send',
  SETTINGS = 'settings',
  SHARE = 'share',
  SPEED = 'speed',
  TRASH = 'trash',
  TRIANGLE_ALERT = 'triangle-alert',
  USER = 'user-round',
  USER_CHECK = 'user-check',
  USER_PLUS = 'user-plus',
  WIFI_OFF = 'wifi-off',
  UPLOAD = 'upload',
  TRAFFIC_CONE = 'traffic-cone',
}

export interface IconsProps {
  icon: Icon;
  className?: string;
  color?: TextColor | string;
  size?: ComponentSize | number;
  tooltip?: string;
  onClick?: () => void;
}

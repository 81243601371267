import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoutes, SIDEBAR_WIDTH } from '../../constants';
import { useFilterOptionsAndSelections } from '../../hooks';
import { ButtonColor, TextButton, Typography, TypographySize, TypographyWeight } from '../shared';
import CallHistoryFilters from './CallHistoryFilters';
import ClearFiltersDialog from './ClearFiltersDialog';
import PracticeFilters from './PracticeFilters';

const Sidebar = () => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const location = useLocation();
  const hasFilters = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return Array.from(params.keys()).length > 0;
  }, [location.search]);

  const isPracticePage = location.pathname.includes(AppRoutes.PRACTICE);
  const isCallHistoryPage = location.pathname.includes(AppRoutes.CALL_HISTORY);

  const { accounts, prospects, tags } = useFilterOptionsAndSelections();

  return (
    <>
      <div className="drawer drawer-open h-full w-fit">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="drawer-side flex h-full w-fit flex-col text-base-content">
          <div className={clsx('flex h-full flex-col border-r border-r-neutral-200 bg-base-0 p-4', SIDEBAR_WIDTH)}>
            <div className="sticky top-0">
              <Typography size={TypographySize.H5} weight={TypographyWeight.MEDIUM}>
                Filters
              </Typography>
            </div>
            <div className="flex flex-1 flex-col gap-6 overflow-y-auto py-6">
              {isPracticePage && (
                <PracticeFilters
                  accountOptionsAndSelections={accounts}
                  prospectOptionsAndSelections={prospects}
                  tagOptionsAndSelections={tags}
                />
              )}
              {isCallHistoryPage && (
                <CallHistoryFilters prospectOptionsAndSelections={prospects} tagOptionsAndSelections={tags} />
              )}
            </div>
            {(isPracticePage || isCallHistoryPage) && hasFilters && (
              <TextButton
                text="Clear filters"
                onClick={() => setIsConfirmModalOpen(true)}
                fullWidth
                color={ButtonColor.SECONDARY}
              />
            )}
          </div>
        </div>
      </div>
      <ClearFiltersDialog isOpen={isConfirmModalOpen} setIsOpen={setIsConfirmModalOpen} />
    </>
  );
};

export default Sidebar;

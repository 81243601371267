import {
  FilterOptionsAndSelections,
  useFeatureFlag,
  useGetFiltersFromParams,
  useUpdateFilterParams,
} from '../../hooks';
import { LD_FeatureFlags, PracticeFilterKeys, PracticeFilters as PracticeFiltersType } from '../../types';
import { parseOptionalSelectOption } from '../../utils';
import { SidebarDateFilter, SidebarSearchFilter, SidebarSelectFilter } from './SidebarFilterFields';

interface PracticeFiltersProps {
  accountOptionsAndSelections: FilterOptionsAndSelections;
  prospectOptionsAndSelections: FilterOptionsAndSelections;
  tagOptionsAndSelections: FilterOptionsAndSelections;
}

const PracticeFilters = ({
  accountOptionsAndSelections,
  prospectOptionsAndSelections,
  tagOptionsAndSelections,
}: PracticeFiltersProps) => {
  // Retrieve filters from URL parameters.
  const filters: PracticeFiltersType = useGetFiltersFromParams();
  const prospectTagsFF = useFeatureFlag(LD_FeatureFlags.RELEASE_PROSPECT_TAGS);

  // Hook to update filter parameters in the URL.
  const updateFilterParams = useUpdateFilterParams();

  return (
    <>
      {/* Search filter for prospects */}
      <SidebarSearchFilter
        onSearchChange={(newValue) => updateFilterParams(PracticeFilterKeys.SEARCH, newValue)}
        placeholder="Search keyword"
        value={filters.search}
      />
      {/* Prospect filter */}
      <SidebarSelectFilter
        title="Prospect"
        placeholder="Select prospect"
        onChange={(newValue) => updateFilterParams(PracticeFilterKeys.PROSPECT, newValue)}
        {...prospectOptionsAndSelections}
      />
      {/* Prospect Account filter */}
      <SidebarSelectFilter
        title="Prospect account"
        placeholder="Select prospect account"
        onChange={(newValue) => updateFilterParams(PracticeFilterKeys.PROSPECT_ACCOUNT, newValue)}
        {...accountOptionsAndSelections}
      />
      {/* Tags filter */}
      {prospectTagsFF && (
        <SidebarSelectFilter
          title="Tags"
          placeholder="Select tag"
          onChange={(newValue) => updateFilterParams(PracticeFilterKeys.TAGS, newValue)}
          {...tagOptionsAndSelections}
        />
      )}
      {/* Date Created filter */}
      <SidebarDateFilter
        title="Date created"
        onChange={(newValue) => updateFilterParams(PracticeFilterKeys.DATE_CREATED, newValue)}
        selected={parseOptionalSelectOption(filters.dateCreated)}
      />
      {/* Last Updated filter */}
      <SidebarDateFilter
        title="Last updated"
        onChange={(newValue) => updateFilterParams(PracticeFilterKeys.LAST_UPDATED, newValue)}
        selected={parseOptionalSelectOption(filters.lastUpdated)}
      />
    </>
  );
};

export default PracticeFilters;

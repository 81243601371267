import { TextColor } from '../types';

/** Maps component type to text color. */
export const TEXT_COLOR_VALUE: Record<TextColor, string> = {
  destructive: 'text-error-content',
  link: 'text-accent',
  primary: 'text-neutral',
  secondary: 'text-neutral-content',
  success: 'text-success-content',
  tertiary: 'text-base-300',
  white: 'text-white',
};

import { Dispatch, SetStateAction, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfirmModal } from '../modals';

interface ClearFiltersDialogProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const ClearFiltersDialog = ({ isOpen, setIsOpen }: ClearFiltersDialogProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const clearFilters = useCallback(() => navigate({ pathname: location.pathname, search: '' }, { replace: true }), []);

  return (
    <ConfirmModal
      buttonText="Clear filters"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onConfirm={() => {
        clearFilters();
        setIsOpen(false);
      }}
      title="Confirm clear filters"
      confirmText="Are you sure you want to clear all filters?"
    />
  );
};

export default ClearFiltersDialog;

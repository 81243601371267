import { useCallback, useEffect, useState } from 'react';
import {
  AlertType,
  ButtonColor,
  ButtonVariant,
  DropdownPlacement,
  Icon,
  IconButton,
  MultiSelect,
} from '../../components';
import { useToast } from '../../hooks';
import { useApplyTagsMutation, useCreateTagMutation, useGetTagsQuery } from '../../services';
import { ComponentSize, Tag, TagColor } from '../../types';

const CREATE_TAG_OPTION = 'CREATE_TAG_OPTION';

interface ManageTagsDropdownProps {
  prospectId: string;
  prospectTags: Tag[];
  selectedTags: Tag[];
  setSelectedTags: (tags: Tag[]) => void;
}

const ManageTagsDropdown = ({ prospectId, prospectTags, selectedTags, setSelectedTags }: ManageTagsDropdownProps) => {
  const [searchValue, setSearchValue] = useState('');

  const { data: tags = [], isLoading: isTagsLoading } = useGetTagsQuery();
  const [applyTags, { isLoading: isApplyingTags }] = useApplyTagsMutation();
  const [createTag, { isLoading: isCreatingTag }] = useCreateTagMutation();

  const { showToast } = useToast();

  const options = tags.map((tag) => ({ color: tag.color, label: tag.name, value: tag.id }));

  const handleCreateTag = useCallback(async () => {
    if (!searchValue.length) return;

    try {
      const randomColor = Object.values(TagColor)[Math.floor(Math.random() * Object.values(TagColor).length)];
      await createTag({ name: searchValue, color: randomColor });
    } catch (error) {
      showToast({ message: `Failed to create tag "${searchValue}"`, type: AlertType.ERROR });
    }
  }, [searchValue, createTag, showToast]);

  const handleApplyTags = useCallback(async () => {
    try {
      // Check if nothing has changed
      const tagsToApply = selectedTags.filter((newTag) => !prospectTags.find((oldTag) => oldTag.id === newTag.id));
      const tagsToRemove = prospectTags.filter((oldTag) => !selectedTags.find((newTag) => newTag.id === oldTag.id));

      if (tagsToApply.length === 0 && tagsToRemove.length === 0) {
        return; // Nothing has changed, so we return early
      }

      // The tags to apply are the selected tags that do not currently exist in the prospect's tags.
      const tagIdsToApply = tagsToApply.map((tag) => tag.id);

      // The tags to remove are the prospect's tags that are not selected anymore.
      const tagIdsToRemove = tagsToRemove.map((tag) => tag.id);

      await applyTags({ prospectId, tagsToApply: tagIdsToApply, tagsToRemove: tagIdsToRemove });
    } catch (error) {
      console.error('Failed to edit tags: ', error);
      showToast({ message: 'Failed to edit tags', type: AlertType.ERROR });
      // Reset the selected tags to the prospect's tags on error.
      setSelectedTags(prospectTags);
    }
  }, [prospectId, selectedTags, prospectTags, applyTags, showToast]);

  useEffect(() => {
    setSelectedTags(prospectTags);
  }, [prospectTags]);

  return (
    <MultiSelect
      options={options}
      selected={selectedTags.map((tag) => ({ label: tag.name, value: tag.id }))}
      onChange={(newValues?: string[]) => {
        const newTags = tags.filter((tag) => newValues?.includes(tag.id));
        setSelectedTags(newTags);
      }}
      placement={DropdownPlacement.BOTTOM_START}
      disabled={isTagsLoading}
      searchProps={{
        searchValue,
        setSearchValue,
        placeholder: 'Type to filter or create',
        disabled: isApplyingTags || isCreatingTag,
        createOption: {
          icon: Icon.PLUS,
          label: `Create new tag "${searchValue}"`,
          value: CREATE_TAG_OPTION,
          onClick: handleCreateTag,
        },
      }}
      customButton={
        <IconButton
          icon={Icon.PLUS}
          color={ButtonColor.SECONDARY}
          size={ComponentSize.X_SMALL}
          variant={ButtonVariant.GHOST}
        />
      }
      onClose={handleApplyTags}
    />
  );
};

export default ManageTagsDropdown;

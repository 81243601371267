import * as RadixAccordion from '@radix-ui/react-accordion';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { ButtonColor, ButtonVariant, IconButton } from '../Button';
import { Icon } from '../Icons';

interface AccordionTriggerProps {
  isActive?: boolean;
}

const AccordionTrigger = forwardRef<HTMLButtonElement, React.HTMLAttributes<HTMLButtonElement> & AccordionTriggerProps>(
  ({ children, className, isActive, ...props }, forwardedRef) => {
    return (
      <RadixAccordion.Header className="AccordionHeader">
        <RadixAccordion.Trigger
          className={clsx('AccordionTrigger flex w-full items-center justify-between', className)}
          {...props}
          ref={forwardedRef}
        >
          {children}
          <IconButton
            icon={isActive ? Icon.MINUS : Icon.PLUS}
            variant={ButtonVariant.GHOST}
            color={ButtonColor.SECONDARY}
          />
        </RadixAccordion.Trigger>
      </RadixAccordion.Header>
    );
  }
);

AccordionTrigger.displayName = 'AccordionTrigger';

export default AccordionTrigger;

export enum LD_ContextKinds {
  USER = 'user',
}

export enum LD_ContextRoles {
  SUPER_ADMIN = 'super-admin',
  SYSTEM_USER = 'system-user',
}

export enum LD_FeatureFlags {
  DETAILED_MEDIA_PLAYER_VIEW = 'detailed-media-player-view',
  RELEASE_CALL_HISTORY_REFRESH = 'release-call-history-refresh',
  RELEASE_DETAILED_MEDIA_PLAYER_COMMENTS = 'release-detailed-media-player-comments',
  RELEASE_INTERCOM_SUPPORT = 'release-intercom-support',
  RELEASE_PROSPECT_TAGS = 'release-prospect-tags',
  HIRING_MODULE = 'hiring-module',
  UPLOAD_MODAL = 'upload-modal',
  UNDER_CONSTRUCTION = 'under-construction',
}

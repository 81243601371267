import { Alignment, TextColor } from '../../../types';

export enum TypographySize {
  CAPTION = 'caption',
  PARAGRAPH = 'paragraph',
  H5 = 'h5',
  H4 = 'h4',
  H3 = 'h3',
  H2 = 'h2',
  H1 = 'h1',
}

export enum TypographyWeight {
  NORMAL = 'normal',
  MEDIUM = 'medium',
  SEMI_BOLD = 'semi-bold',
  BOLD = 'bold',
}

export interface TypographyProps {
  children: React.ReactNode;
  alignment?: Alignment;
  className?: string;
  color?: TextColor | string;
  size?: TypographySize;
  underline?: boolean;
  weight?: TypographyWeight;
  onClick?: () => void;
}

export type TypographyComponent = React.ReactElement<TypographyProps>;

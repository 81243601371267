import {
  AlertCircle,
  ArchiveX,
  ArrowDownWideNarrow,
  ArrowUpDown,
  ArrowUpWideNarrow,
  BarChart,
  Building2,
  Captions,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsUpDown,
  CircleCheck,
  CircleX,
  Copy,
  EllipsisVertical,
  ExternalLink,
  Gauge,
  Handshake,
  Headphones,
  History,
  Info,
  Link,
  Lock,
  LogOut,
  MessageCircle,
  Minus,
  Pause,
  Phone,
  Play,
  Plus,
  RotateCcw,
  RotateCw,
  Search,
  Send,
  Settings,
  Share2,
  Trash2,
  TriangleAlert,
  Upload,
  UserCheck,
  UserPlus,
  UserRound,
  WifiOff,
  X,
  TrafficCone,
} from 'lucide-react';
import { ComponentSize } from '../../../types';
import { Icon } from './Icons.types';

export const ICON_MAP = {
  [Icon.ARCHIVE]: ArchiveX,
  [Icon.ARROW_SORT_ASC]: ArrowUpWideNarrow,
  [Icon.ARROW_SORT_DESC]: ArrowDownWideNarrow,
  [Icon.ARROW_SORT_NONE]: ArrowUpDown,
  [Icon.BAR_CHART]: BarChart,
  [Icon.CAPTION]: Captions,
  [Icon.CHECK]: Check,
  [Icon.CHEVRON_DOWN]: ChevronDown,
  [Icon.CHEVRON_LEFT]: ChevronLeft,
  [Icon.CHEVRON_RIGHT]: ChevronRight,
  [Icon.CHEVRON_UP]: ChevronUp,
  [Icon.CHEVRONS_UP_DOWN]: ChevronsUpDown,
  [Icon.CIRCLE_ALERT]: AlertCircle,
  [Icon.CIRCLE_CHECK]: CircleCheck,
  [Icon.CIRCLE_X]: CircleX,
  [Icon.CLOSE]: X,
  [Icon.COMMENT]: MessageCircle,
  [Icon.COPY]: Copy,
  [Icon.ELLIPSIS_VERTICAL]: EllipsisVertical,
  [Icon.FAST_FORWARD]: RotateCw,
  [Icon.HANDSHAKE]: Handshake,
  [Icon.HEADPHONES]: Headphones,
  [Icon.HISTORY]: History,
  [Icon.INFO]: Info,
  [Icon.LINK]: Link,
  [Icon.LOCK]: Lock,
  [Icon.LOG_OUT]: LogOut,
  [Icon.MINUS]: Minus,
  [Icon.OPEN]: ExternalLink,
  [Icon.ORGANIZATION]: Building2,
  [Icon.PAUSE]: Pause,
  [Icon.PLAY]: Play,
  [Icon.PHONE]: Phone,
  [Icon.PLUS]: Plus,
  [Icon.REWIND]: RotateCcw,
  [Icon.SEARCH]: Search,
  [Icon.SEND]: Send,
  [Icon.SETTINGS]: Settings,
  [Icon.SHARE]: Share2,
  [Icon.SPEED]: Gauge,
  [Icon.TRASH]: Trash2,
  [Icon.TRIANGLE_ALERT]: TriangleAlert,
  [Icon.USER]: UserRound,
  [Icon.USER_CHECK]: UserCheck,
  [Icon.USER_PLUS]: UserPlus,
  [Icon.WIFI_OFF]: WifiOff,
  [Icon.UPLOAD]: Upload,
  [Icon.TRAFFIC_CONE]: TrafficCone,
};

export const ICON_SIZE: Record<ComponentSize, number> = {
  [ComponentSize.X_SMALL]: 12,
  [ComponentSize.SMALL]: 16,
  [ComponentSize.MEDIUM]: 20,
  [ComponentSize.LARGE]: 24,
};

import { SelectOption } from '../components';
import { useGetPracticeProspectsForSelectQuery, useGetTagsQuery } from '../services';
import { PracticeFilters } from '../types';
import { getSelectedOptions, parseOptionalSelectOption, parseRequiredSelectOption } from '../utils';
import useGetFiltersFromParams from './useGetFiltersFromParams';

export type FilterOptionsAndSelections = {
  /** The options for the filter. */
  options: SelectOption[];
  /** Whether the options are loading. */
  loading: boolean;
  /** The selected options for the filter. */
  selected?: SelectOption[];
};

// Custom hook to manage filter options and selections.
const useFilterOptionsAndSelections = (): Record<string, FilterOptionsAndSelections> => {
  // Fetch all prospects and tags.
  const { data: prospects = [], isLoading: isProspectsLoading } = useGetPracticeProspectsForSelectQuery();
  const { data: tags = [], isLoading: isTagsLoading } = useGetTagsQuery();

  // Get current filters from URL parameters.
  const filters = useGetFiltersFromParams();

  // Create unique account options from prospect data.
  const accountOptions = Array.from(new Set(prospects.map((p) => p.accountName))).map(parseRequiredSelectOption);

  // Transform prospect data into SelectOption format.
  const prospectOptions = prospects.map(({ personaId, firstName, lastName }) => ({
    value: personaId,
    label: `${firstName} ${lastName}`,
  }));

  // Transform tag data into SelectOption format.
  const tagOptions = tags.map(({ id, name, color }) => ({ value: id, label: name, color }));

  // Parse selected values into SelectOption format.
  const selectedAccounts = parseOptionalSelectOption((filters as PracticeFilters).prospectAccount);
  const selectedProspects = getSelectedOptions(prospectOptions, filters.prospect);
  const selectedTags = getSelectedOptions(tagOptions, filters.tags);

  // Return an object with filter options, selections, and loading states for each filter type.
  return {
    accounts: {
      options: accountOptions,
      selected: selectedAccounts,
      loading: isProspectsLoading,
    },
    prospects: {
      options: prospectOptions,
      selected: selectedProspects,
      loading: isProspectsLoading,
    },
    tags: {
      options: tagOptions,
      selected: selectedTags,
      loading: isTagsLoading,
    },
  };
};

export default useFilterOptionsAndSelections;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum ModalTypes {
  SETTINGS = 'settings',
}

export enum SettingsModalTabs {
  PROFILE = 'profile',
  ORGANIZATION = 'organization',
  HIRING = 'hiring',
}

interface SettingsModalState {
  isOpen: boolean;
  activeTab: SettingsModalTabs;
}

interface UploadModalState {
  isOpen: boolean;
}

interface ModalState {
  settings: SettingsModalState;
  upload: UploadModalState;
}

const initialModalState: ModalState = {
  settings: {
    isOpen: false,
    activeTab: SettingsModalTabs.PROFILE,
  },
  upload: {
    isOpen: false,
  },
};

const modalSlice = createSlice({
  name: 'modal',
  initialState: initialModalState,
  reducers: {
    openSettingsModal: (state, action: PayloadAction<SettingsModalTabs | undefined>) => {
      state.settings.isOpen = true;
      if (action.payload) {
        state.settings.activeTab = action.payload;
      } else {
        state.settings.activeTab = SettingsModalTabs.PROFILE;
      }
    },
    closeSettingsModal: (state) => {
      state.settings.isOpen = false;
    },
    setSettingsActiveTab: (state, action: PayloadAction<SettingsModalTabs>) => {
      state.settings.activeTab = action.payload;
    },
    openUploadModal: (state) => {
      state.upload.isOpen = true;
    },
    closeUploadModal: (state) => {
      state.upload.isOpen = false;
    },
  },
});

export const { openSettingsModal, closeSettingsModal, setSettingsActiveTab, openUploadModal, closeUploadModal } =
  modalSlice.actions;
export default modalSlice.reducer;

/**
 * Enum for text and icon colors in our design system.
 */
export enum TextColor {
  DESTRUCTIVE = 'destructive',
  LINK = 'link',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  TERTIARY = 'tertiary',
  WHITE = 'white',
}

/**
 * Enum for semantic colors in our design system.
 * Semantic colors represent abstract concepts in our design system,
 * such as primary actions, secondary elements, and accent highlights.
 * These colors are used consistently across the application to maintain
 * a cohesive visual language and reinforce the meaning of UI elements.
 */
export enum SemanticColor {
  PRIMARY = 'semantic-primary',
  SECONDARY = 'semantic-secondary',
  ACCENT = 'semantic-accent',
}

export type Color = TextColor | SemanticColor;

/**
 * Enum for base colors in our design system.
 * Base colors are used as the foundation for the design system,
 * providing a consistent color scheme across the application.
 */
export enum BaseColor {
  NEUTRAL = 'neutral',
  BASE_100 = 'base-100',
  BASE_200 = 'base-200',
  BASE_300 = 'base-300',
}

/**
 * Enum for status colors in our design system.
 * Status colors are used to convey meaning or purpose in the UI,
 * rather than specific visual styles.
 */
export enum StatusColor {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

/**
 * Enum for tag colors in our design system.
 * Tag colors are used to label and categorize content, providing a way to organize and
 * group information in a visually appealing and intuitive manner.
 */
export enum TagColor {
  RED = '#F44336',
  PINK = '#E91E63',
  PURPLE = '#9C27B0',
  INDIGO = '#4050B5',
  BLUE = '#00A9F4',
  TEAL = '#009688',
  LIME = '#CDDC39',
  YELLOW = '#FFEB3B',
  ORANGE = '#FF9800',
}

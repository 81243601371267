import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { KeyboardEvent, useCallback, useState } from 'react';
import { useHandleApiResponse, useToast } from '../../../../../hooks';
import { useCreateCandidateMutation } from '../../../../../services';
import { TextColor } from '../../../../../types';
import { checkPhoneNumberError } from '../../../../../utils';
import {
  AlertType,
  PhoneNumberInput,
  TextButton,
  TextInput,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../../../shared';
import { US_COUNTRY_CODE } from '../../../../../constants';

const ERROR_MSG = 'Failed to add candidate';

const AddCandidate = () => {
  // States to manage first name input and error state.
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);

  // States to manage last name input and error state.
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState(false);

  // States to manage phone number input and error state.
  const [countryCode, setCountryCode] = useState(US_COUNTRY_CODE);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  // State to manage error messages
  const [error, setError] = useState('');

  // Custom hooks
  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  // Mutations
  const [createCandidate, { isLoading }] = useCreateCandidateMutation();

  const onError = useCallback(
    (fetchError: FetchBaseQueryError) => {
      const errorMsg = checkPhoneNumberError(fetchError);
      if (errorMsg) {
        setError(errorMsg);
        setPhoneNumberError(true);
        showToast({ message: errorMsg, type: AlertType.ERROR });
      }
    },
    [setError, setPhoneNumberError, showToast]
  );

  const onSuccess = useCallback(() => {
    setFirstName('');
    setLastName('');
    setPhoneNumber('');
  }, [setFirstName, setLastName, setPhoneNumber]);

  // Callback function to handle creating a new candidate.
  const handleCreateCandidate = useCallback(async () => {
    if (error.length) return;

    const isEmpty = !firstName.length || !lastName.length || !phoneNumber.length || countryCode === '+';
    if (isEmpty) {
      setError('Required fields cannot be empty');
      setFirstNameError(!firstName.length);
      setLastNameError(!lastName.length);
      setPhoneNumberError(!phoneNumber.length || countryCode === '+');
      return;
    }

    const payload = { firstName, lastName, phoneNumber: countryCode + phoneNumber };

    try {
      const response = await createCandidate(payload);
      handleApiResponse({ response, errorMsg: ERROR_MSG, onError, onSuccess });
    } catch (e) {
      console.error(`${ERROR_MSG}: `, e);
      showToast({ message: ERROR_MSG, type: AlertType.ERROR });
    }
  }, [countryCode, phoneNumber, firstName, lastName, handleApiResponse, showToast, onError, onSuccess]);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleCreateCandidate();
    }
  };

  const resetError = useCallback(() => {
    if (error.length) {
      setError('');
      setFirstNameError(false);
      setLastNameError(false);
      setPhoneNumberError(false);
    }
  }, [error, setError, setFirstNameError, setLastNameError, setPhoneNumberError]);

  return (
    <div>
      <div className="flex w-full flex-col gap-4">
        <Typography weight={TypographyWeight.SEMI_BOLD} size={TypographySize.H5}>
          Create new candidate
        </Typography>
        <div className="flex gap-2">
          <TextInput
            placeholder="First name*"
            onChange={(e) => {
              setFirstName(e.target.value);
              resetError();
            }}
            value={firstName}
            error={firstNameError}
            onKeyDown={handleKeyDown}
          />
          <TextInput
            placeholder="Last name*"
            onChange={(e) => {
              setLastName(e.target.value);
              resetError();
            }}
            value={lastName}
            error={lastNameError}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="flex gap-2">
          <PhoneNumberInput
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            resetError={resetError}
            error={phoneNumberError}
            onKeyDown={handleKeyDown}
          />
          <div className="w-32">
            <TextButton text="Invite" onClick={handleCreateCandidate} fullWidth loading={isLoading} />
          </div>
        </div>
      </div>
      <div className="h-4">
        <Typography size={TypographySize.CAPTION} color={TextColor.DESTRUCTIVE}>
          {error}
        </Typography>
      </div>
    </div>
  );
};

export default AddCandidate;

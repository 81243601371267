import Intercom from '@intercom/messenger-js-sdk';
import { LD_FeatureFlags } from '../types';
import useAppSelector from './useAppSelector';
import useFeatureFlag from './useFeatureFlag';

const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID as string;

const useIntercom = () => {
  const { user, organization } = useAppSelector((state) => state.auth);
  const intercomSupportFF = useFeatureFlag(LD_FeatureFlags.RELEASE_INTERCOM_SUPPORT);

  if (intercomSupportFF) {
    Intercom({
      app_id: APP_ID,
      email: user?.email,
      user_id: user?.id,
      name: user?.name,
      user_hash: user?.intercomHmac,
      company: {
        id: organization?.id,
        name: organization?.name,
      },
    });
  }
};

export default useIntercom;

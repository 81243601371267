import { MultiSelect, MultiSelectProps, Typography } from '../../shared';

interface SidebarSelectFilterProps extends MultiSelectProps {
  title: string;
}

const SidebarSelectFilter = ({ title, ...selectOptions }: SidebarSelectFilterProps) => {
  return (
    <div className="flex flex-col gap-2">
      <Typography>{title}</Typography>
      <MultiSelect {...selectOptions} clearable />
    </div>
  );
};

export default SidebarSelectFilter;

import { User as Auth0User } from '@auth0/auth0-react';
import { GOOGLE_USER_ID_PREFIX, ROLE_HIERARCHY, ROLE_LABEL } from '../constants';
import { AppUser, Auth0Roles, Permissions, Roles, SortableUser } from '../types';

/**
 * Gets the top role from a list of roles
 */
export function getTopRole(roles: Roles[]): Roles {
  return roles.sort((a, b) => roles.indexOf(a) - roles.indexOf(b))[0];
}

/**
 * Parses Auth0 user data into application format.
 */
export const getParsedAuth0User = (user: Auth0User): AppUser => {
  // Destructure necessary fields.
  const { created_at, disabled, email, user_id, roles, name, permissions, picture, intercom_hmac } = user;

  const getPicture = () => {
    if (!user_id) return;

    const isGoogleUser = user_id.includes(GOOGLE_USER_ID_PREFIX);
    return isGoogleUser ? picture : undefined;
  };

  const getRole = () => {
    if (!roles) return;

    const parsedRoles = roles.map((role: { id: string; name: Roles } | Roles) =>
      typeof role === 'string' ? role : role.name
    );
    const topRole = getTopRole(parsedRoles);
    return topRole;
  };

  // Create a new object with the keys formatted to follow JavaScript naming conventions
  // and ensure 'name' is undefined if it matches the email (to avoid redundant data).
  const parsedUser = {
    createdAt: created_at,
    disabled,
    email,
    id: user_id,
    role: getRole(),
    name,
    permissions,
    picture: getPicture(),
    intercomHmac: intercom_hmac,
  };

  return parsedUser;
};

/**
 * Checks if a role is higher than or equal to another role
 * @param currUserRole - The role of the current user
 * @param comparedRole - The role to compare against
 * @param isEqual - Whether to include equality in the comparison
 * @returns boolean - True if the current role is higher than or equal to the compared role, false otherwise
 */
export function isHigherRole(currUserRole: Roles, comparedRole?: Roles, isEqual = false): boolean {
  // Get the hierarchy level of the current role and the compared role.
  const roleA = ROLE_HIERARCHY[currUserRole];
  const roleB = comparedRole ? ROLE_HIERARCHY[comparedRole] : ROLE_HIERARCHY.noRole;

  // Return true if the current role is higher than or equal to the compared role.
  if (isEqual) {
    return roleA >= roleB;
  } else {
    return roleA > roleB;
  }
}

/**
 * Generates the role options for a user based on their current role
 * @param currUser - The current user object
 * @param isEqual - Whether to include equality in the role comparison
 * @returns Array<{ label: string, value: Role }> - The available role options
 */
export const getRoleOptions = (currUser?: AppUser, isEqual?: boolean) => {
  const { role: userRole, permissions: userPermissions } = currUser || {};

  // Return an empty array if the current user's role is not defined.
  if (!userRole) return [];

  const isSuperAdmin = userPermissions?.includes(Permissions.ADMIN_ACCESS);

  // Filter roles based on the current user's role and map them to the desired format.
  return Object.values(Roles)
    .filter((role) => isSuperAdmin || isHigherRole(userRole, role, isEqual))
    .map((role) => ({ label: ROLE_LABEL[role], value: role }));
};

export const transformAuth0Role = (role: Auth0Roles): Roles => {
  if (role === Auth0Roles.SUPER_ADMIN) return Roles.SUPER_ADMIN;
  if (role === Auth0Roles.ADMIN) return Roles.ADMIN;
  if (role === Auth0Roles.MANAGER) return Roles.MANAGER;
  return Roles.SALES_REP;
};

export const sortUsers = (a: SortableUser, b: SortableUser) => {
  // Helper function to get the last name from the full name
  const getLastName = (name: string) => {
    const parts = name.trim().split(' ');
    return parts.length > 1 ? parts[parts.length - 1] : parts[0];
  };

  // Disabled users are always at the bottom
  if (a.disabled && !b.disabled) return 1;
  if (!a.disabled && b.disabled) return -1;

  // Get last names, fallback to email if name is not available
  const lastNameA = a.name ? getLastName(a.name).toLowerCase() : a.email?.toLowerCase() || 0;
  const lastNameB = b.name ? getLastName(b.name).toLowerCase() : b.email?.toLowerCase() || 0;

  if (lastNameA < lastNameB) return -1;
  if (lastNameA > lastNameB) return 1;

  // If last names are equal, fallback to full name or email comparison
  const nameA = a.name?.toLowerCase() || a.email?.toLowerCase() || 0;
  const nameB = b.name?.toLowerCase() || b.email?.toLowerCase() || 0;

  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;

  return 0;
};

/**
 * Splits a full name into first and last name.
 * If there are more than two parts, all parts except the last one are joined as the first name.
 */
export const splitName = (fullName?: string) => {
  if (!fullName?.length) return ['', ''];

  const nameParts = fullName.trim().split(' ');

  if (nameParts.length === 1) {
    return [nameParts[0], ''];
  }

  const lastName = nameParts.pop() as string;
  const firstName = nameParts.join(' ');

  return [firstName, lastName];
};

/**
 * Checks if a phone number is valid.
 */
export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  const phoneNumberPattern = /^\+\d{11,18}$/;
  return phoneNumberPattern.test(phoneNumber);
};

import { AlertType, ButtonColor, ButtonVariant, TextButton, TextInput } from '../../shared';
import SubmitSuccessState from './SubmitSuccessState';
import { useToast } from '../../../hooks';
import { useSubmitLinkMutation } from '../../../services';
import { isValidURL } from '../../../utils';
import { ACCORDION_TRIGGER_ICON_SIZE } from './UploadModal';
import { useCallback } from 'react';

const SUBMIT_LINK_ERROR_MSG = 'Failed to submit link';

interface SubmitLinkProps {
  linkInput: string;
  setLinkInput: React.Dispatch<React.SetStateAction<string>>;
  linkError: string;
  setLinkError: React.Dispatch<React.SetStateAction<string>>;
  isLinkSubmitted: boolean;
  setIsLinkSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}

const SubmitLink = ({
  linkInput,
  setLinkInput,
  linkError,
  setLinkError,
  isLinkSubmitted,
  setIsLinkSubmitted,
}: SubmitLinkProps) => {
  // Hooks
  const { showToast } = useToast();

  // RTK Query
  const [submitLink, { isLoading: isSubmittingLink }] = useSubmitLinkMutation();

  // Handler function to manage link change
  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkInput(e.target.value);
    setLinkError('');
  };

  // Handler function to manage link submission
  const handleLinkSubmit = useCallback(async () => {
    if (!linkInput) return;

    // Validate URL
    if (!isValidURL(linkInput)) {
      setLinkError('Please enter a valid URL');
      return;
    }

    try {
      // Submit link to the backend
      await submitLink(linkInput);
      setIsLinkSubmitted(true);
    } catch (error) {
      console.error(`${SUBMIT_LINK_ERROR_MSG}: `, error);
      showToast({ message: SUBMIT_LINK_ERROR_MSG, type: AlertType.ERROR });
    }
  }, [linkInput, submitLink, setIsLinkSubmitted, showToast]);

  const handleSubmitAnother = () => {
    setLinkInput('');
    setLinkError('');
    setIsLinkSubmitted(false);
  };

  if (isLinkSubmitted) return <SubmitSuccessState handleSubmitAnother={handleSubmitAnother} />;

  return (
    <div className="mt-2 flex gap-2" style={{ width: `calc(100% - ${ACCORDION_TRIGGER_ICON_SIZE + 32}px)` }}>
      <TextInput placeholder="https://" value={linkInput} onChange={handleLinkChange} error={linkError} />
      <TextButton
        text="Submit"
        loading={isSubmittingLink}
        onClick={handleLinkSubmit}
        color={ButtonColor.SECONDARY}
        disabled={!linkInput}
        variant={ButtonVariant.OUTLINE}
        className="mb-4"
      />
    </div>
  );
};

export default SubmitLink;

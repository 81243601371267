import { Pagination } from './api.types';
import { Tag } from './tags.types';

type PracticeProspectWithoutTags = {
  associatedPhoneNumber: string;
  createdAt: Date;
  createdBy: string;
  firstMessage: string;
  firstName: string;
  jobTitle: string;
  lastName: string;
  accountName: string;
  personaId: string;
  personaPrompt: string;
  personaVoiceProvider: string;
  personaVoiceId: string;
  lastModified: Date;
  lastModifiedBy: string;
  notes?: string;
};

export type PracticeProspect = PracticeProspectWithoutTags & { tags: Tag[] };

export type PracticeProspectsResponse = {
  data: {
    prospects: (PracticeProspectWithoutTags & { tags: { tag: Tag }[] })[];
    pagination: Pagination;
  };
};

export enum ProspectSortingFilters {
  ASSOCIATED_PHONE_NUMBER = 'associatedPhoneNumber',
  FIRST_NAME = 'firstName',
  CREATED_AT = 'createdAt',
}

/** Represents data related to a prospect. */
export type ProspectColumnData = {
  // The company where the prospect works.
  company: string;
  // The job title of the prospect.
  jobTitle: string;
  // The full name of the prospect.
  name: string;
  // The ID of the prospect.
  id?: string;
  // The tags associated with the prospect.
  tags?: Tag[];
};

export type ProspectColumn = {
  phoneNumber: string;
  prospect: ProspectColumnData;
  createdAt: Date;
  notes?: string;
};

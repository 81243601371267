import { AlertType, ButtonColor, ButtonVariant, FileInput, TextButton } from '../../shared';
import SubmitSuccessState from './SubmitSuccessState';
import { useToast } from '../../../hooks';
import { useGenerateUploadUrlMutation, useUploadFileMutation } from '../../../services';
import { ACCORDION_TRIGGER_ICON_SIZE } from './UploadModal';
import { useCallback } from 'react';

const SUBMIT_FILE_ERROR_MSG = 'Failed to submit file';

interface UploadFileProps {
  selectedFile: File | undefined;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  isFileSubmitted: boolean;
  setIsFileSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadFile = ({ selectedFile, setSelectedFile, isFileSubmitted, setIsFileSubmitted }: UploadFileProps) => {
  // Hooks
  const { showToast } = useToast();

  // RTK Query
  const [generateUploadUrl, { isLoading: isGeneratingUploadUrl }] = useGenerateUploadUrlMutation();
  const [uploadFile, { isLoading: isUploadingFile }] = useUploadFileMutation();

  // Handler function to manage file submission
  const handleFileSubmit = useCallback(async () => {
    if (!selectedFile) return;

    try {
      // Generate Upload URL
      const { type, name, size } = selectedFile;
      const uploadUrlResponse = await generateUploadUrl({ type, name, size });
      if (uploadUrlResponse.data?.presignedUrl) {
        // Upload File to the Generated URL
        await uploadFile({
          file: selectedFile,
          uploadUrl: uploadUrlResponse.data.presignedUrl,
        });
        setIsFileSubmitted(true);
      } else {
        showToast({ message: SUBMIT_FILE_ERROR_MSG, type: AlertType.ERROR });
      }
    } catch (error) {
      console.error(`${SUBMIT_FILE_ERROR_MSG}: `, error);
      showToast({ message: SUBMIT_FILE_ERROR_MSG, type: AlertType.ERROR });
    }
  }, [selectedFile, generateUploadUrl, uploadFile, setIsFileSubmitted, showToast]);

  const handleSubmitAnother = () => {
    // clear selected file and error
    setSelectedFile(undefined);
    setIsFileSubmitted(false);
  };

  if (isFileSubmitted) return <SubmitSuccessState handleSubmitAnother={handleSubmitAnother} />;

  return (
    <div
      className="mt-2 flex items-start gap-2"
      style={{ width: `calc(100% - ${ACCORDION_TRIGGER_ICON_SIZE + 32}px)` }}
    >
      <FileInput selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
      <TextButton
        text="Submit"
        onClick={handleFileSubmit}
        color={ButtonColor.SECONDARY}
        disabled={!selectedFile}
        variant={ButtonVariant.OUTLINE}
        loading={isUploadingFile || isGeneratingUploadUrl}
      />
    </div>
  );
};

export default UploadFile;

import React, { useEffect, useRef, useState } from 'react';
import { ComponentSize, TextColor } from '../../../../types';
import { BadgeDot } from '../../BadgeDot';
import { ButtonColor, ButtonVariant, IconButton } from '../../Button';
import { Dropdown, DropdownContent, DropdownTrigger } from '../../Dropdown';
import { Icon } from '../../Icons';
import { TextInput } from '../../TextInput';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../Tooltip';
import { Typography } from '../../Typography';
import { SELECT_DROPDOWN_MAX_HEIGHT, SELECT_ICON_SIZE } from './BaseSelect.constants';
import { BaseSelectProps } from './BaseSelect.types';
import BaseSelectOption from './BaseSelectOption';
import BaseSelectSearch from './BaseSelectSearch';

/**
 * BaseSelect is a reusable dropdown component that can be used for both single-select and multi-select.
 * It is used internally by the SingleSelect and MultiSelect components.
 */
const BaseSelect = ({
  customButton,
  displayValue,
  displayValueColor,
  options,
  clearable,
  disabled,
  loading,
  multiSelect,
  placeholder,
  placement,
  searchProps,
  selected,
  size = ComponentSize.SMALL,
  tooltip,
  handleClear,
  handleOptionSelection,
  onClose,
}: BaseSelectProps) => {
  const [hoveredIndex, setHoveredIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const listRef = useRef<HTMLLIElement[]>([]);

  const filteredOptions = searchProps
    ? options.filter((option) => option.label.toLowerCase().includes(searchProps.searchValue.toLowerCase()))
    : options;

  const optionsToRender =
    filteredOptions.length > 0 || !searchProps?.createOption || !searchProps.searchValue.length
      ? filteredOptions
      : [searchProps?.createOption];

  // If there are no options to render, we should disable the dropdown
  // unless it has a search field.
  const canOpenEmpty = !optionsToRender.length && !searchProps;
  const shouldDisable = disabled || loading || canOpenEmpty;

  const runOptionClick = (value: string) => {
    handleOptionSelection(value);
    if (!multiSelect) {
      setIsOpen(false);
    }
  };

  const renderEndElement = () => (
    <div className="flex items-center">
      {/*
       * Multi-select only shows the first selected option,
       * so we show a +(N-1) button to indicate there are more selected options.
       */}
      {Array.isArray(selected) && selected.length > 1 && (
        <div className="flex h-6 w-6 items-center justify-center">
          <Typography color={TextColor.SECONDARY}>+{selected.length - 1}</Typography>
        </div>
      )}
      {/* Optional clear button to clear all selected options. */}
      {clearable && (
        <Tooltip>
          <TooltipTrigger>
            <IconButton
              icon={Icon.CLOSE}
              variant={ButtonVariant.GHOST}
              color={ButtonColor.SECONDARY}
              size={SELECT_ICON_SIZE[size]}
              onClick={handleClear}
            />
          </TooltipTrigger>
          <TooltipContent>Clear</TooltipContent>
        </Tooltip>
      )}
      {/* Toggle button to open/close the dropdown. */}
      <IconButton
        icon={isOpen ? Icon.CHEVRON_UP : Icon.CHEVRON_DOWN}
        size={SELECT_ICON_SIZE[size]}
        onClick={() => setIsOpen((prev) => !prev)}
        variant={ButtonVariant.GHOST}
        color={ButtonColor.SECONDARY}
        disabled={shouldDisable}
      />
    </div>
  );

  // Resets the hovered index whenever the length of the options change (ie. due to filtering).
  useEffect(() => {
    setHoveredIndex(0);
    listRef.current = listRef.current.slice(0, filteredOptions.length);
  }, [filteredOptions.length]);

  // Runs onClose only when the dropdown goes from open to closed.
  const prevIsOpenRef = useRef(isOpen);
  useEffect(() => {
    if (prevIsOpenRef.current && !isOpen && onClose) {
      onClose();
    }
    prevIsOpenRef.current = isOpen;
  }, [isOpen, onClose]);

  return (
    <Dropdown
      open={isOpen}
      onOpenChange={setIsOpen}
      placement={placement}
      disabled={shouldDisable}
      fullWidth
      listNavigation={{
        activeIndex: hoveredIndex,
        setActiveIndex: (index) => setHoveredIndex(index === null ? 0 : index),
        listRef,
      }}
    >
      <DropdownTrigger>
        <Tooltip>
          <TooltipTrigger fullWidth>
            {!customButton && (
              <TextInput
                placeholder={placeholder}
                value={displayValue}
                size={size}
                endElement={renderEndElement()}
                startElement={displayValueColor ? <BadgeDot color={displayValueColor} /> : undefined}
                disabled={shouldDisable}
              />
            )}
            {customButton &&
              React.isValidElement(customButton) &&
              React.cloneElement(customButton, {
                disabled: shouldDisable,
                onClick: () => setIsOpen((prev) => !prev),
              })}
          </TooltipTrigger>
          <TooltipContent>{tooltip}</TooltipContent>
        </Tooltip>
      </DropdownTrigger>
      <DropdownContent className="flex flex-col gap-1">
        {searchProps && <BaseSelectSearch {...searchProps} />}
        {!!optionsToRender.length && (
          <div
            className="display-scrollbar flex flex-col gap-1 overflow-y-auto"
            style={{ maxHeight: SELECT_DROPDOWN_MAX_HEIGHT }}
          >
            {optionsToRender.map((option, index) => (
              <BaseSelectOption
                key={index}
                option={option}
                index={index}
                size={size}
                hovered={hoveredIndex === index}
                listRef={listRef}
                multiSelect={multiSelect}
                runOptionClick={runOptionClick}
              />
            ))}
          </div>
        )}
      </DropdownContent>
    </Dropdown>
  );
};

export default BaseSelect;

import { DateOptions } from './date.types';

/**
 * Enum to define the keys used for practice filters in the URL and state.
 */
export enum PracticeFilterKeys {
  DATE_CREATED = 'dateCreated',
  LAST_UPDATED = 'lastUpdated',
  PROSPECT = 'prospect',
  PROSPECT_ACCOUNT = 'prospectAccount',
  SEARCH = 'search',
  TAGS = 'tags',
}

/**
 * Enum to define the keys used for call filters in the URL and state.
 */
export enum CallHistoryFilterKeys {
  CALL_DATE = 'callDate',
  CALLER = 'caller',
  PROSPECT = 'prospect',
  TAGS = 'tags',
}

export type FilterKeys = CallHistoryFilterKeys | PracticeFilterKeys;

/**
 * Type definition for the filters used in the practice page.
 */
export type PracticeFilters = {
  [PracticeFilterKeys.DATE_CREATED]?: DateOptions[];
  [PracticeFilterKeys.LAST_UPDATED]?: DateOptions[];
  [PracticeFilterKeys.PROSPECT]?: string[];
  [PracticeFilterKeys.PROSPECT_ACCOUNT]?: string[];
  [PracticeFilterKeys.SEARCH]?: string;
  [PracticeFilterKeys.TAGS]?: string[];
};

/**
 * Type definition for the filters used in the call page.
 */
export type CallHistoryFilters = {
  [CallHistoryFilterKeys.CALL_DATE]?: DateOptions[];
  [CallHistoryFilterKeys.CALLER]?: string[];
  [CallHistoryFilterKeys.PROSPECT]?: string[];
  [CallHistoryFilterKeys.TAGS]?: string[];
};

export enum Roles {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  SALES_REP = 'SALES_REP',
}

export type User = {
  id: string;
  email: string;
  name: string;
  picture: string;
  createdAt: string;
  updatedAt: string;
};

export type OrgMember = User & {
  role: Roles;
  disabled: boolean;
  onboarded: boolean;
};

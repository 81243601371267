import clsx from 'clsx';
import { FocusEvent, KeyboardEvent, forwardRef } from 'react';
import { ComponentSize, TextColor } from '../../../types';
import { Icons } from '../Icons';
import { Typography, TypographySize } from '../Typography';
import { INPUT_SIZE } from './TextInput.constants';
import './TextInput.css';
import { TextInputProps, TextInputType } from './TextInput.types';

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      disabled,
      startElement,
      startIcon,
      error,
      size = ComponentSize.SMALL,
      endElement,
      placeholder,
      type = TextInputType.TEXT,
      value = '',
      width,
      onBlur,
      onChange,
      onKeyDown,
    },
    ref
  ) => {
    const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        event.stopPropagation();
        onBlur(event);
      }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (onKeyDown) {
        onKeyDown(event);
      }
    };

    return (
      <div className={clsx(!width && 'w-full', className)} style={{ width }}>
        <label
          className={clsx(
            'input input-bordered flex items-center gap-2 border-base-300 bg-base-0 !outline-none',
            INPUT_SIZE[size],
            disabled && 'input-disabled cursor-auto border-transparent bg-base-100 text-neutral',
            error && '!border-error-content bg-error'
          )}
        >
          {startElement && <div className="min-w-fit">{startElement}</div>}
          {startIcon && <Icons icon={startIcon} />}
          <input
            ref={ref}
            type={type}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={onChange}
            className="w-full"
            onKeyDown={handleKeyDown}
            onBlur={handleOnBlur}
            readOnly={!onChange || disabled}
            data-1p-ignore={type !== TextInputType.PASSWORD}
            onClick={(e) => {
              // File input should open the file explorer
              if (type !== TextInputType.FILE) {
                e.stopPropagation();
                e.preventDefault();
              }
            }}
          />
          {endElement && endElement}
        </label>
        {typeof error === 'string' && (
          <div className="h-4">
            <Typography size={TypographySize.CAPTION} color={TextColor.DESTRUCTIVE}>
              {error}
            </Typography>
          </div>
        )}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';
export default TextInput;

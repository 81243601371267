import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useState } from 'react';
import { ROLE_LABEL } from '../../../../../constants';
import { useAppSelector, useHandleApiResponse, useToast } from '../../../../../hooks';
import { useInviteUserMutation } from '../../../../../services';
import { Permissions, Roles } from '../../../../../types';
import { checkInviteUserError, getRoleOptions } from '../../../../../utils';
import { AlertType, Select, TextButton, TextInput } from '../../../../shared';

const ERROR_MSG = 'Failed to invite user';

const AddUser = () => {
  // State
  const [emailInput, setEmailInput] = useState('');
  const [selectedRole, setSelectedRole] = useState<Roles>(Roles.SALES_REP);
  const [error, setError] = useState('');

  // Redux
  const currUser = useAppSelector((state) => state.auth.user);
  // Check if the curr user is allowed to invite other users.
  const permissions = currUser?.permissions || [];
  const userCannotInvite = !permissions.includes(Permissions.INVITE_USER);

  // Custom hooks
  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  // Mutations
  const [inviteUser, { isLoading }] = useInviteUserMutation();

  // Return null if the curr user does not have permission to invite other users.
  if (!currUser || userCannotInvite) return null;

  // Include equality in the role comparison if the current user is an admin
  // to allow admins to invite other admins.
  const roleOptions = getRoleOptions(currUser, currUser.role === Roles.ADMIN);

  const handleInvite = async () => {
    if (error.length) return;

    if (!emailInput.length) {
      setError('Field cannot be empty');
      return;
    }

    if (selectedRole === Roles.SUPER_ADMIN && !emailInput?.endsWith('@fullyramped.com')) {
      setError('Email must end with "@fullyramped.com" for super admin role');
      return;
    }

    try {
      const response = await inviteUser({ role: selectedRole, email: emailInput });

      const onError = (e: FetchBaseQueryError) => {
        const errorMessage = checkInviteUserError(e);
        if (errorMessage) {
          setError(errorMessage);
        }
      };

      const onSuccess = () => setEmailInput('');

      handleApiResponse({ response, errorMsg: ERROR_MSG, onError, onSuccess });
    } catch (error) {
      console.error(`${ERROR_MSG}: `, error);
      showToast({ message: ERROR_MSG, type: AlertType.ERROR });
    }
  };

  return (
    <div className="flex items-center gap-2">
      <TextInput
        placeholder="Add user by email"
        width="50%"
        onChange={(e) => {
          setEmailInput(e.target.value);
          if (error.length) {
            setError('');
          }
        }}
        value={emailInput}
        error={error}
        disabled={isLoading}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleInvite();
          }
        }}
      />
      <div className="mb-4 flex w-1/2 gap-2">
        <Select
          options={roleOptions}
          selected={{ label: ROLE_LABEL[selectedRole], value: selectedRole }}
          onChange={(newRole) => setSelectedRole(newRole as Roles)}
          disabled={isLoading}
        />
        <div className="w-32">
          <TextButton text="Invite" onClick={handleInvite} fullWidth loading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default AddUser;
